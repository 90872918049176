export function deepCopy(obj) {
    let copiedObject = obj;

    if (obj && typeof obj === "object") {
        copiedObject = Array.isArray(obj) ? [] : {};
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (obj[key] && typeof obj[key] === "object") {
                    copiedObject[key] = deepCopy(obj[key]);
                } else {
                    copiedObject[key] = obj[key];
                }
            }
        }
    }

    return copiedObject;
}

export function capitalizeFirstCharatcter(string) {
    return typeof string === "string" ? string.charAt(0).toUpperCase() + string.slice(1) : "";
}

export function toastApiMessage(inputString) {
    inputString = capitalizeFirstCharatcter(inputString);
    inputString = inputString.replaceAll(".", "");
    return inputString + ".";
}