import React, { useEffect, useRef, useState } from 'react'
import { successMessage } from './toastMessages/ToastifyMsg';
// import FadeIn from 'react-fade-in/lib/FadeIn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare, faChevronUp } from '@fortawesome/free-solid-svg-icons';

function ScrollToTop() {
    const [scroll, setScroll] = useState(false);
    const scrollRef = useRef(null);

    const showScroll = () => {
        if (window.scrollY > 95) {
            // console.log("window.scrollY>>",window.scrollY)
            setScroll(true)
        } else {
            setScroll(false)
        }
    }

    useEffect(() => {

        window.addEventListener("scroll", showScroll)
    })

    const handleScroll = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    const handleCopySharLink = ()=>{
        const currentUrl = window.location.href;
        navigator.clipboard.writeText(currentUrl);
        successMessage("Shareable Link Copied")
    }

    return (
        // <FadeIn>
        <div className={`scroll-wrapper`} >
            {/* btn-custom */}
            <div className={`scroll-inner-wrapper ${scroll ? ' active' : ''}`} ref={scrollRef} onClick={handleScroll} title="Scroll To Top"><span className="label text-light"><FontAwesomeIcon icon={faChevronUp} /> </span></div>
            <div className="scroll-inner-wrapper shareable-wrapper" onClick={()=>handleCopySharLink()}><span className="label text-light"><FontAwesomeIcon icon={faShare} /> </span> </div>

{/* </FadeIn> */ }
        </div>
        
    )
}

export default ScrollToTop