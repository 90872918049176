import React, { useCallback, useEffect, useRef, useState,Suspense } from 'react'
import LoadingImage from '../../assets/images/loading-gif.gif';
// import {
    //     // Magnifier,
    //     GlassMagnifier,
    //     // SideBySideMagnifier,
    //     // PictureInPictureMagnifier,
    //     // MOUSE_ACTIVATION,
    //     // TOUCH_ACTIVATION
    // } from "@user-bob/react-image-magnifiers";
    // // import PictureInPictureMagnifier from '@user-bob/react-image-magnifiers/dist/PictureInPictureMagnifier';
    import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
    import { faChevronLeft, faChevronRight, faSearch } from '@fortawesome/free-solid-svg-icons';
import Description from '../description/Description';
    const LazyLoadImage = React.lazy(()=>import('../lazyload/LazyLoadImage'));




function GallerySlider({imgBaseUrl, logoBaseUrl , manfacture, galleryImages, showHideCount, countRef, view, handleGalleryModal, content}) {
    const [images, setImages] = useState([]);
    const [url,setUrl] =useState(null);
    const [logoUrl,setLogoUrl] = useState(null);
    const [descContent,setDescContent] = useState("");
    const [slideNumber, setSlideNumber] = useState(0);
    const [manfactureInfo,setManfactureInfo] = useState(null);
    // const [zoomModal, setZoomModal] = useState(false);

    // const imageLoadRef = useRef(null);
    // const imageLoadTimeoutRef = useRef(null);


    // const handleCloseZoomModal = useCallback(() => {
    //     setZoomModal(false)
    // }, [])

useEffect(() => {
if (content) {
    setDescContent(content);
}
}, [content])

    useEffect(() => {
        if (content) {
            setDescContent(content);
        }
        if (galleryImages.length > 0) {
            const  allNewImages = content?[...galleryImages,{ }]:[...galleryImages] 
            setImages(allNewImages);
            setUrl(imgBaseUrl);
            setLogoUrl(logoBaseUrl);
            setManfactureInfo(manfacture);
        }
    }, [galleryImages,imgBaseUrl,logoBaseUrl,manfacture,content])


    //     const showHideCount = useCallback(() => {
    //         const showClass = 'show';
    //   const element = countRef.current;

    //   if (element.classList.contains(showClass)) {
    //     // Clear any existing timeout.
    //     clearTimeout(timeoutIdRef.current);

    //     // Set a new timeout to remove the class after 5 seconds.
    //     timeoutIdRef.current = setTimeout(() => {
    //       element.classList.remove(showClass);
    //     }, 5000);
    //   } else {
    //     element.classList.add(showClass);

    //     // Clear any existing timeout.
    //     clearTimeout(timeoutIdRef.current);

    //     // Set a new timeout to remove the class after 5 seconds.
    //     timeoutIdRef.current = setTimeout(() => {
    //       element.classList.remove(showClass);
    //     }, 5000);
    //   }
    //     }, [])

    // const imageLoader = useCallback(() => {
    //     const imageLoadWrapper = imageLoadRef.current;
    //     clearTimeout(imageLoadTimeoutRef.current);
    //     imageLoadWrapper.style.opacity = '0';

    //     setTimeout(() => {
    //         imageLoadWrapper.style.opacity = '1';
    //     }, 1000);
    // }, [])

    const prevSlide = () => {
        // imageLoader();
        showHideCount();
        slideNumber === 0 ? setSlideNumber(images.length - 1) : setSlideNumber(slideNumber - 1);
    }

    // Next Image  
    const nextSlide = () => {
        // imageLoader();
        showHideCount();
        slideNumber + 1 === images.length ? setSlideNumber(0) : setSlideNumber(slideNumber + 1)

    }


    // zoom in

    // const handleZoomIn = () => {
    //     setZoomModal(!zoomModal);
    // }


    return (
        <>
            <div className="product-img-slider-inner-wrapper">
                <div className={`position-relative text-center w-100 ${view !== 'quickView' ? 'h-100' : ''}`} >
                    <div className="img-count-container" ref={countRef}> <span>{slideNumber + 1}/{images.length}</span></div>
                    {images[slideNumber]?.image &&
                    // <img className='bg-light' loading="lazy" class="lazyload-img" src={`${url}/${images[slideNumber]?.image}`} alt={`${images[slideNumber]?.image}`} ref={imageLoadRef} />
                        // <img src={LoadingImage} alt='loading...' class="lazyload-img" />
                        <Suspense fallback={<img src={LoadingImage} alt='loading...' className="lazyload-img"/>}>
                            <LazyLoadImage  src={`${url}/${images[slideNumber]?.image}`} alt={`${images[slideNumber]?.image}`} classes='bg-light'/>
                        </Suspense>
                    }
                    {descContent && images.length-1 === slideNumber &&
                        <div className='slider-text-wrapper'>
                                <Description  content={descContent}/>
                        </div>
                    }
                </div>
                {images.length >1 && (
                    <>
                    <FontAwesomeIcon icon={faChevronLeft} className='btnPrev text-light' onClick={prevSlide} />
                    <FontAwesomeIcon icon={faChevronRight} className='btnNext text-light' onClick={nextSlide}/>
                        {/* <i className="fa fa-chevron-left btnPrev" onClick={prevSlide}></i>
                        <i className="fa fa-chevron-right btnNext" onClick={nextSlide}></i> */}
                    </>    
                )}
                {manfactureInfo?.logo && (
                    <div className="manfacture-logo"><img src={`${logoUrl}/${manfactureInfo?.logo}`} loading='lazy' alt="mrf-logo"/></div>
                )}
                {/* <div className="zoom-in-btn-container" onClick={handleZoomIn}><i className="fas fa-search-plus"></i></div> */}  
                <div className='overlay' onClick={()=>handleGalleryModal(descContent&&images.length-1 === slideNumber ?0:slideNumber)}></div>
                <div className="gallery-popup-btn" onClick={() => handleGalleryModal(descContent&&images.length-1 === slideNumber ?0:slideNumber)}> <FontAwesomeIcon icon={faSearch} /></div>
            </div>
           

          
            
        


        </>

    )
}

export default GallerySlider