import React, { useCallback, useEffect, useRef, useState } from 'react'
// import GallerySlider from './gallery/GallerySlider'
import FadeIn from 'react-fade-in/lib/FadeIn';
import { Link } from 'react-router-dom';
import ProductDetailCardGallery from './ProductDetailCardGallery';
// import Select from 'react-select'
// import makeAnimated from 'react-select/animated';
import { deepCopy } from '../utils/utils';
import Description from './description/Description';
// import DownloadFilesButton from './DownloadFilesButton';
import submittedImg from '../assets/images/feedbackSubmit.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload,faHome, 
    // faThumbsDown,faThumbsUp
 } from '@fortawesome/free-solid-svg-icons';

import { faThumbsUp,faThumbsDown } from '@fortawesome/free-regular-svg-icons';

// const animatedComponents = makeAnimated();





function ProductCardDetail({ feedbackOptions, requestOptions, product, paths, handleAddToCart, handleGalleryImagesModal, handleOpenEmailModal,submittedFeedbacks }) {
    
    // ======================== View 2 ==========================

    const [singleProduct, setSingleProduct] = useState(null);
    const [files, setFiles] = useState([]);
    const [galleryImages, setGalleryImages] = useState([]);
    const [baseUrls, setBaseUrls] = useState(null);
    const [manfactureInfo, setManfactureInfo] = useState(null);
    const [reqOptions, setReqOptions] = useState([]);
    const [intresets, setIntresets] = useState("");
    const [isSubmitted,setIsSubmitted] = useState(false);

    const [reference1,setReference1] = useState("");
    const [reference2,setReference2] = useState("");
    const [comment,setComment] = useState("");
    const [selectedFeedback,setSelectedFeedback] = useState([]);
    const [selectedRequest,setSelectedRequest] = useState([]);
    const [submittedFeedbackOpt,setSubmittedFeedbackOpt] = useState(null);


    // const countRef = useRef(null);
    // const countTimeoutIdRef = useRef(null);
    // const [addedToCart, setAddedToCart] = useState(false);
    const [reference, setReference] = useState(false);

    useEffect(() => {
        const productCopy = deepCopy(product);
        const pathCopy = deepCopy(paths);
        // console.log(paths)
        setBaseUrls(pathCopy);
        setFiles(productCopy?.files);
        setSingleProduct(prevState => ({
            ...prevState,
            id: productCopy?.id,
            name: productCopy?.name,
            title: productCopy?.title,
            manufacturer_id: productCopy?.manufacturer_id,
            description: productCopy?.description,
            website: productCopy?.website,
            zip_path: productCopy?.zip_path
        }));
        setManfactureInfo(productCopy?.manufacturer_name)
        setGalleryImages(productCopy?.images);
        // console.log("productCopy?.images",productCopy?.images)

    }, [product, paths])

    useEffect(() => {
        setReqOptions(requestOptions);
    }, [requestOptions])

    useEffect(() => {
        if (submittedFeedbacks.length>0 && singleProduct) {
            const checkFeed = submittedFeedbacks.some(feedback => feedback?.id === singleProduct?.id);
            const submittedOpt = submittedFeedbacks.filter(feedback => feedback?.id === singleProduct?.id)[0];
            if (submittedOpt) {
                const interestType = submittedOpt?.feedbacktype ==="1"?'Interested': submittedOpt?.feedbacktype ==="2"?'Maybe In the Future': submittedOpt?.feedbacktype ==="4" ? 'Previously Specified':'Not Interested';
                submittedOpt.interestType=interestType;
            }
            setSubmittedFeedbackOpt(submittedOpt);
            setIsSubmitted(checkFeed);
        }
    }, [submittedFeedbacks,singleProduct])

    // const addProductToCart = (e) => {
    //     // console.log("addProductToCart",e.target.value)
    //     // console.log("addProductToCart",e.target.checked)
    //     setAddedToCart(!addedToCart);

    //     handleAddToCart(productDetails)
    // }

    // useEffect(() => {
    //     setGalleryImages(imgArry)
    // }, [imgArry])

    // const showHideCount = useCallback(() => {
    //     const showClass = 'show';
    //     const displayClass = 'd-none'
    //     const countWrapper = countRef.current;
    //     // const tagsWrapper = proTagsRef.current;

    //     if (countWrapper.classList.contains(showClass)) {
    //         // Clear any existing timeout.
    //         clearTimeout(countTimeoutIdRef.current);

    //         countTimeoutIdRef.current = setTimeout(() => {
    //             countWrapper.classList.remove(showClass);
    //             // tagsWrapper.classList.remove(displayClass);
    //         }, 2000);

    //     } else {
    //         // tagsWrapper.classList.add(displayClass);
    //         countWrapper.classList.add(showClass);

    //         // Clear any existing timeout.
    //         clearTimeout(countTimeoutIdRef.current);

    //         countTimeoutIdRef.current = setTimeout(() => {
    //             countWrapper.classList.remove(showClass);
    //             // tagsWrapper.classList.remove(displayClass);
    //         }, 2000);
    //     }
    // }, []);


    const handleInterest = (interest) => {
        const interestType =  interest ==="intreseted"?"1":interest ==="maybe_in_future"?"2":interest ==="previously_specified" ? '4':'3'
      setIntresets(interestType);
      setReference(false);
      let body = {
        comment: "",
        request_info: [],
        feedback: [],
        reference1: "",
        reference2: ""
    }
      handleOpenEmailModal(singleProduct?.id,interestType,body);
    }
    
    const handleSubmit = (e)=>{
        e.preventDefault();
        let interType =  intresets ==="intreseted"?"1":intresets ==="maybe_in_future"?"2":intresets ==="previously_specified" ? '4':'3';
        let requestbody = JSON.stringify(selectedRequest.map(opt=>opt?.id));
        let fedbackbody = JSON.stringify(selectedFeedback.map(opt=>opt?.value));
        let body = {
            comment: comment,
            request_info: requestbody,
            feedback: fedbackbody,
            reference1: reference1,
            reference2: reference2
        }
        // console.log("body",body)
        handleOpenEmailModal(singleProduct?.id,interType,body);
    }
    // const handleChangeRequest = (selectedOption)=>{
    //     setSelectedRequest(selectedOption);
    // }
    
    // const handleChangeFeedback = (selectedOption) => {
    //     setSelectedFeedback(selectedOption);
    //   };
    
    const handleViewFilesGallery = (viewMode)=>{
        handleGalleryModal(galleryImages,0, viewMode);
    }

    
      const handleGalleryModal = (images , selectedImage, viewMode)=>{
        const mrfLogo =  manfactureInfo?.logo ? `${baseUrls?.logo_path}/${manfactureInfo?.logo}`:null;
        const fileFullUrl = files?.map(file=>({...file,file_path:`${baseUrls?.files_path}/${file?.file}`}));
        handleGalleryImagesModal(images,selectedImage,mrfLogo,fileFullUrl,viewMode,singleProduct?.zip_path);
      }

    return (
        <div className="col-12">
            <FadeIn delay={0}>
                <div className="product-card-list-wrapper">
                    <div className="product-card-list-inner-wrapper">
                        {/* <div className="product-img-slider-wrapper">
                            <GallerySlider galleryImages={galleryImages} countRef={countRef} showHideCount={showHideCount} />
                            <div className="gallery-modal-opener" onClick={()=>handleGalleryImagesModal(imgArry,0)}></div>
                        </div> */}
                        <ProductDetailCardGallery galleryImages={galleryImages} imgBaseUrl={baseUrls?.image_path} handleGalleryImagesModal={handleGalleryModal} />
                        <div className="product-card-body">

                            <div className="title-like-container">
                                <div className="product-title-container">
                                    {/* <div className="checkbox-num-wrapper"> */}
                                    {/* <div className="checkbox-wrapper-custom">
                                        <input type="checkbox" name={inputId} onChange={(e) => addProductToCart(e)} checked={addedToCart} />
                                        <label className="cbx" htmlFor={inputId}>
                                            <div className="flip">
                                                <div className="front"></div>
                                                <div className="back">
                                                    <svg width="15" height="13" viewBox="0 0 16 14">
                                                        <path d="M2 8.5L6 12.5L14 1.5"></path>
                                                    </svg>
                                                </div>
                                            </div>
                                        </label>
                                    </div> */}
                                    {/* <label htmlFor={inputId} className="pro-num">{inputId}</label>
                                </div> */}

                                    <h2 className="product-name">
                                        {/* <label htmlFor={inputId}>
                                        <span>{(Math.floor(Math.random() * 11))}.</span></label> */}
                                        {singleProduct?.name}
                                    </h2>


                                </div>
                                {/* <div className="like-button-wrapper">
                                    <i className="fa fa-thumbs-up"></i> <span>Like</span>
                                </div> */}
                            </div>

                            <p className="product-title">{singleProduct?.title}</p>

                            <div className="description">
                                <Description content={singleProduct?.description} />

                            </div>
                            {/* <div className="tags-container">
                                <div className="tags-inner-container">
                                    <div className="tag product-from"> <span>listed by redfin 14 hr ago</span></div>
                                    <div className="tag type"> <span>3d walkthrough</span></div>
                                </div>
                            </div> */}
                            <div className="social-links-container">
                                {singleProduct?.website &&
                                    <div className="social-links"> <Link to={singleProduct?.website} target='_blank'>
                                         <FontAwesomeIcon icon={faHome}/> 
                                    {/* <i className="fa fa-home"></i> */}
                                     <span>  Visit website</span> </Link></div>
                                }
                                {singleProduct?.zip_path &&
                                <div className="social-links" onClick={()=>handleViewFilesGallery(1)}> <FontAwesomeIcon icon={faDownload}/>
                                 {/* <i className="fa fa-download"></i>  */}
                                 <span>  Download Brochure</span></div>
                                    // <DownloadFilesButton zipBaseUrl={baseUrls?.zip_path} zipEndpoint={singleProduct?.zip_path} handleViewFilesGallery={handleViewFilesGallery}/>
                                }
                               
                                {/* {!isSubmitted &&   
                                    <div className="social-links " onClick={() => setReference(!reference)}>
                                        <i className="fas fa-lightbulb"></i>
                                        <span> I have a Project!</span>
                                    </div>
                                } */}
                            </div>
                            <div className="price-action-wrapper">

                                {!isSubmitted &&
                                    <div className={`col-12 intresets-btn-wapper`}>
                                        <div className="intreset-wrapper">
                                            {/* <button className={`intreset-btn${intresets === 'not_intreseted' ? ' active' : ''}`} type="button" onClick={() => handleInterest('not_intreseted')}>
                                                <i className={`${intresets === 'not_intreseted' ? 'fa' : 'far'} far fa-thumbs-down`}></i>
                                                <FontAwesomeIcon icon={faThumbsDown}/>
                                                 <span> Not Interested</span>
                                            </button> */}
                                            <button className={`intreset-btn${intresets === 'intreseted' ? ' active intreseted' : ''}`} type="button" onClick={() => handleInterest("intreseted")}>
                                                {/* <i className={`${intresets === 'intreseted' ? 'fa' : 'far'} far fa-thumbs-up`}></i> */}
                                                {/* <FontAwesomeIcon icon={faThumbsUp}/> */}
                                                <span>Interested</span>
                                            </button>
                                            <button className={`intreset-btn${intresets === 'maybe_in_future' ? ' active love-it' : ''}`} type="button" onClick={() => handleInterest("maybe_in_future")}><span>Maybe in the future</span></button>
                                            {/* <button className={`intreset-btn${intresets === 'previously_specified' ? ' active love-it' : ''}`} type="button" onClick={() => handleInterest("previously_specified")}><span>Previously Specified</span></button> */}

                                        </div>
                                    </div>
                                }                                <div className="col-12 form-wrapper-view-3">
                                    <div className="comment-wrapper">
                                        {/* {reference && !isSubmitted && (
                                            <FadeIn>
                                                <div className="reference-projects-wrapper col-12">
                                                    <div className="field-wrapper">
                                                        <input type="text" id={`reference1_${singleProduct?.id}`} autoComplete='off' name={`reference1_${singleProduct?.id}`} className="form-control" placeholder='Add project name. . .' onChange={(e)=> setReference1(e.target.value)}/>
                                                    </div>
                                                    <div className="field-wrapper">
                                                        <input type="text" id={`reference2_${singleProduct?.id}`} autoComplete='off' name={`reference2_${singleProduct?.id}`} className="form-control" placeholder='Add project name. . .' onChange={(e)=> setReference2(e.target.value)}/>
                                                    </div>

                                                </div>
                                            </FadeIn>
                                        )
                                        } */}
                                        {(intresets === "intreseted" || intresets === "maybe_in_future") && !isSubmitted &&
                                        <form onSubmit={handleSubmit}>
                                            <div className="reference-projects-wrapper col-12">
                                                    {/* {intresets === "intreseted" &&
                                                        <div className="field-wrapper">
                                                            <input type="text" id={`reference1_${singleProduct?.id}`} autoComplete='off' name={`reference1_${singleProduct?.id}`} className="form-control" placeholder='Add project name . . .' onChange={(e)=> setReference1(e.target.value)} autoFocus/>
                                                        </div>
                                                    } */}
                                                    {/* <div className="field-wrapper">
                                                        <input type="text" id={`reference2_${singleProduct?.id}`} autoComplete='off' name={`reference2_${singleProduct?.id}`} className="form-control" placeholder='Add project name. . .' onChange={(e)=> setReference2(e.target.value)}/>
                                                    </div> */}

                                                </div>
                                            {/* <div className="select-feeback-wrapper">
                                                <Select
                                                    closeMenuOnSelect={true}
                                                    classNamePrefix='custom-input'
                                                    components={animatedComponents}
                                                    options={reqOptions}
                                                    isMulti
                                                    onChange={handleChangeRequest}
                                                    // onChange={handleFeedBackSelection}
                                                    placeholder='Request . . .'
                                                    required

                                                />
                                            </div>
                                            <div className="select-feeback-wrapper">
                                                <Select
                                                    closeMenuOnSelect={true}
                                                    classNamePrefix='custom-input'
                                                    components={animatedComponents}
                                                    options={feedbackOptions}
                                                    isMulti
                                                    onChange={handleChangeFeedback}
                                                    // onChange={handleFeedBackSelection}
                                                    placeholder='Select Feedback'
                                                    required
                                                />

                                            </div> */}

                                            {/* <div className="col-12">
                                                <textarea className='form-control' placeholder='Add a comment . . . ' rows={1} name='comment' id='comment' onChange={(e)=> setComment(e.target.value)}/>
                                                <div className='submit-btn-wrapper'>
                                                    <button type="submit" className="btn">Submit</button>
                                                </div>
                                            </div> */}
                                        </form>
                                        }
                                    </div>
                                </div> 

                                
                                {/* <div className="actions-container">
                                    <div className="actions-inner-container">
                                        <span>Let's talk!</span> <div className={`action add-to-cart${addedToCart ? ' added' : ''}`} title={`${addedToCart ? 'Remove From' : 'Add To'} Enquiry List`} >  <i className="fa fa-plus"></i></div>
                                        <div className="action quick-view" title='Quick View' onClick={() => handleQuickView('image')}><i className="fa fa-search"></i></div>
                                    </div>
                                </div> */}
                            </div>
                            
                            {isSubmitted &&
                                <div className="form-status-wrapper">
                                    <div className="form-status-inner-wrapper">
                                        <h2 className="form-status-title">
                                        {/* {submittedFeedbackOpt?.feedbacktype ==="1" &&<FontAwesomeIcon icon={faThumbsUp} />}
                                        {submittedFeedbackOpt?.feedbacktype ==="3" && <FontAwesomeIcon icon={faThumbsDown}/>}
                                        {submittedFeedbackOpt?.feedbacktype !=="1" && submittedFeedbackOpt?.feedbacktype !=="3" && */}
                                            <img src={submittedImg} alt={`feedback Submited ${singleProduct?.id}`} />    
                                        {/* } */}
                                        {/* <span className='ps-1'>{submittedFeedbackOpt?.interestType }</span> */}
                                             Feedback submitted!
                                        </h2>
                                        
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </FadeIn>
            {/* <div className="social-links">
                <div className="checkbox-wrapper-custom-sm">
                    <input id={inputId} type="checkbox" name={inputId} onChange={(e)=> addProductToCart(e)} checked={addedToCart}/>
                    <label className="cbx" htmlFor={inputId}>
                        <div className="flip">
                            <div className="front"></div>
                            <div className="back">
                                <svg width="10" height="10" viewBox="0 0 16 14">
                                    <path d="M2 8.5L6 12.5L14 1.5"></path>
                                </svg>
                            </div>
                        </div>
                    </label>
                </div>
                <span> <label htmlFor={inputId}> Add to cart</label></span>
            </div> */}
        </div>
        
    )
}

export default ProductCardDetail