import React, {  useRef } from 'react';

const VideoWrapper = ({ video, index, currentVideo, setCurrentVideo }) => {
  const videoRef = useRef(null);
//   const [isPlaying, setIsPlaying] = useState(false);

  const handleMouseEnter = () => {
    // setIsPlaying(true);
    if (videoRef.current) videoRef.current.play();
  };

  const handleMouseLeave = () => {
    // setIsPlaying(false);
    if (videoRef.current) videoRef.current.pause();
  };

  return (
    <div
      className={`single-pane-video-wrapper${index === currentVideo ? ' active' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={()=>setCurrentVideo(index)}
    >
      <video ref={videoRef} controls={false} loop muted>
        <source src={video?.file_path} type="video/mp4" />
        {/* <source src="movie.ogg" type="video/ogg"/> */}
        Your browser does not support the video tag.
      </video>
      {/* {isPlaying && } */}
    </div>
  );
};

export default function VideoList({ videoFiles, currentVideo, setCurrentVideo }) {
  return (
    <>
      {videoFiles.map((video, index) => (
        <VideoWrapper key={index} video={video} index={index} currentVideo={currentVideo} setCurrentVideo={setCurrentVideo}/>
      ))}
    </>
  );
};