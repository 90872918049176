import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { BrowserRouter } from "react-router-dom";
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Provider } from 'react-redux';
// import { store } from './store/store';
import { persistor, store } from './redux/store'
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
// import GlobalErrorBoundary from './errorHandling/GlobalErrorBoundary';
// import { QueryClient, QueryClientProvider } from 'react-query'

// const queryClient = new QueryClient()

import { disableReactDevTools } from '@fvilers/disable-react-devtools';


if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( 
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        {/* <GlobalErrorBoundary> */}
        <ToastContainer
          autoClose={4000}
          position="top-right"
        />
        {/* <QueryClientProvider client={queryClient}> */}
          <App />
        {/* </QueryClientProvider> */}
        {/* </GlobalErrorBoundary> */}
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
