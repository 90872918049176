import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import FadeIn from 'react-fade-in/lib/FadeIn'
import GallerySlider from './gallery/GallerySlider';
// import { Link } from 'react-router-dom';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import { deepCopy } from '../utils/utils';
import Description from './description/Description';
// import DownloadFiles from './DownloadFilesButton';
import DownloadFilesButton from './DownloadFilesButton';

import submitted from '../assets/images/feedbackSubmit.png'
import { Link } from 'react-router-dom';


const animatedComponents = makeAnimated();

// const feedbackOptions = [
//     { value: 'Not Interested', label: 'Not Interested' },
//     { value: "Haven't used this product in the past", label: "Haven't used this product in the past" },
//     { value: 'I use this type product on projects', label: 'I use this type product on projects' },
//     { value: "Download data for reference only", label: "Download data for reference only" },
// ]

function ProductFullCard({ feedbackOptions, product, paths, requestOptions, viewType, handleAddToCart, handleGalleryModal, handleOpenEmailModal, submittedFeedbacks  }) {
     
                // =========================View 3 = card  AND  Veiw 4 = cardOptions ====================
    
    const [singleProduct, setSingleProduct] = useState(null);
    const [files, setFiles] = useState([]);
    const [galleryImages, setGalleryImages] = useState([]);
    const [baseUrls, setBaseUrls] = useState(null);
    const [manfactureInfo, setManfactureInfo] = useState(null);
    const [reqOptions, setReqOptions] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [reference1,setReference1] = useState("");
    const [reference2,setReference2] = useState("");
    const [comment,setComment] = useState("");
    const [selectedFeedback,setSelectedFeedback] = useState([]);
    const [selectedRequest,setSelectedRequest] = useState([]);


    const [selectedImage, setSelectedImage] = useState(0);

    // const [addedToCart, setAddedToCart] = useState(false);
    const [reference, setReference] = useState("");
    const [intresets, setIntresets] = useState(null);


    const countRef = useRef(null);
    const countTimeoutIdRef = useRef(null);
    const proTagsRef = useRef(null);

    useEffect(() => {
        const productCopy = deepCopy(product);
        const pathCopy = deepCopy(paths);
        // console.log(productCopy);
        // console.log(paths)
        setBaseUrls(pathCopy);
        setFiles(productCopy?.files);
        setSingleProduct(prevState => ({
            ...prevState,
            id: productCopy?.id,
            name: productCopy?.name,
            title: productCopy?.title,
            manufacturer_id: productCopy?.manufacturer_id,
            description: productCopy?.description,
            website: productCopy?.website,
            zip_path: productCopy?.zip_path
        }));
        setManfactureInfo(productCopy?.manufacturer_name)
        setGalleryImages(productCopy?.images);

    }, [product, paths])

    useEffect(() => {
        setReqOptions(requestOptions);
    }, [requestOptions])

    useEffect(() => {
        if (submittedFeedbacks.length>0 && singleProduct) {
            const checkFeed = submittedFeedbacks.some(feedback => feedback === singleProduct?.id);
            // console.log("checkFeed:",checkFeed)
            setIsSubmitted(checkFeed);
        }
    }, [submittedFeedbacks,singleProduct])



    // const addProductToCart = (e) => {
    //     setAddedToCart(!addedToCart);

    //     handleAddToCart(productDetails);
    // }


    const showHideCount = useCallback(() => {
        const showClass = 'show';
        // const displayClass = 'd-none'
        const countWrapper = countRef.current;
        // const tagsWrapper = proTagsRef.current;
        setTimeout(() => {
            let index = parseInt(countWrapper.children[0].innerHTML.split("/")[0]) - 1;
            setSelectedImage(index);
        }, 100);

        if (countWrapper.classList.contains(showClass)) {
            // Clear any existing timeout.
            clearTimeout(countTimeoutIdRef.current);
            // console.log(countWrapper.child);

            countTimeoutIdRef.current = setTimeout(() => {
                countWrapper.classList.remove(showClass);
                // tagsWrapper.classList.remove(displayClass);
            }, 2000);

        } else {
            // tagsWrapper.classList.add(displayClass);
            countWrapper.classList.add(showClass);

            // Clear any existing timeout.
            clearTimeout(countTimeoutIdRef.current);

            countTimeoutIdRef.current = setTimeout(() => {
                countWrapper.classList.remove(showClass);
                // tagsWrapper.classList.remove(displayClass);
            }, 2000);
        }
    }, []);

    //   console.log(object)
    const handleViewFilesGallery = (viewMode)=>{
        handleGalleryImages(viewMode);
    }

    const handleGalleryImages = (viewMode) => {
        const mrfLogo = manfactureInfo?.logo ? `${baseUrls?.logo_path}/${manfactureInfo?.logo}`:null;
        const fileFullUrl = files?.map(file=>({...file,file_path:`${baseUrls?.files_path}/${file?.file}`}));
        handleGalleryModal(galleryImages, selectedImage,mrfLogo,fileFullUrl,viewMode,singleProduct?.zip_path);
    }

    const handleNotInterested = (interest) => {
        setIntresets(interest);
        setReference(null);
        let body = {
            comment: comment,
            request_info: "",
            feedback: "",
            reference1: reference1,
            reference2: reference2
        }
        handleOpenEmailModal(singleProduct?.id,"3",body);
    }


    const handleSubmit = (e)=>{
        e.preventDefault();
        const interType= intresets ==="intreseted"?"1":intresets ==="maybe_in_future"?"2":"3";
       
        let requestbody = JSON.stringify(selectedRequest.map(opt=>opt?.id));
        let fedbackbody = JSON.stringify(selectedFeedback.map(opt=>opt?.value));
        let body = {
            comment: comment,
            request_info: requestbody,
            feedback: fedbackbody,
            reference1: reference1,
            reference2: reference2
        }
        // console.log("body",body,interType)
        handleOpenEmailModal(singleProduct?.id, interType,body);
    }

    // const handleChangeRequest = (selectedOption)=>{
    //     setSelectedRequest(selectedOption);
    // }
    
    // const handleChangeFeedback = (selectedOption) => {
    //     setSelectedFeedback(selectedOption);
    //   };

    const handleInterest = (int)=>{
        setIntresets(int);
        setReference1("");
        setReference2("");
    } 

    const handleChangeReference = (value)=>{
        setReference(value);
        setReference1("");
        setReference2("");
    }

    const shouldRender = (
        viewType !== "cardOptions" ||
        (viewType === "cardOptions" && (((intresets === "intreseted" && (reference === 'current_project' || reference === 'references')) || intresets === "maybe_in_future")) ) 
    )

    return (
        <div className="col-12">
            <FadeIn>
                <div className="product-detail-card-wrapper">
                    <div className="product-detail-card-inner-wrapper">
                        <div className="detail-card-gallery-wrapper">
                            <div className="product-img-slider-wrapper">
                                <GallerySlider imgBaseUrl={baseUrls?.image_path} logoBaseUrl={baseUrls?.logo_path} manfacture={manfactureInfo} galleryImages={galleryImages} showHideCount={showHideCount} countRef={countRef} />
                                <div className="gallery-overlay" onClick={() => handleGalleryImages(0)}></div>
                                <div className="gallery-popup-btn" onClick={() => handleGalleryImages(0)}><i className="fa fa-search"></i></div>
                            </div>
                        </div>
                        <div className="detail-card-description-wrapper">
                            <div className="detail-card-description-inner-wrapper">
                                <div className="detail-card-description-body">
                                    <div className="product-title-container">
                                        {/* <div className="checkbox-num-wrapper"> */}
                                        {/* <div className="checkbox-wrapper-custom">
                                            <input id={inputId} type="checkbox" onChange={(e) => addProductToCart(e)} checked={addedToCart} />
                                            <label className="cbx" htmlFor={inputId}>
                                                <div className="flip">
                                                    <div className="front"></div>
                                                    <div className="back">
                                                        <svg width="15" height="13" viewBox="0 0 16 14">
                                                            <path d="M2 8.5L6 12.5L14 1.5"></path>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </label>
                                        </div> */}
                                        {/* <label htmlFor={inputId} className="pro-num">{inputId}</label>
                                        </div> */}

                                        <h3 className="product-name">
                                            {/* <label htmlFor={inputId}> */}

                                            {singleProduct?.name}
                                            {/* </label> */}
                                        </h3>
                                        {/* <div className="like-button-wrapper">
                                            <i className="fa fa-thumbs-up"></i> <span>interested</span> 
                                        </div> */}
                                    </div>
                                    <p className="product-title">{singleProduct?.title}</p>


                                    <div className="description">
                                        <Description content={singleProduct?.description} />
                                    </div>

                                </div>

                                <div className="full-detail-footer">
                                    {/* {viewType !== "cardOptions" && */}
                                        <div className="social-links-container">
                                            {/* <div className="social-links" style={{ 'cursor': 'not-allowed', 'pointerEvents': 'none', }}>  <i className="fa fa-download"></i> <span>  Download Brochure</span></div> */}
                                            {/* <div className="social-links" onClick={()=>handleOpenEmailModal()}> <i className="fas fa-at"></i> <span> Email supplier</span></div> */}
                                            {singleProduct?.website &&
                                                <div className="social-links mx-2"> <Link to={singleProduct?.website} target='_blank'> <i className="fa fa-home"></i> <span>  Visit website</span> </Link></div>
                                            }
                                            {singleProduct?.zip_path &&
                                                <div className='mx-2'>
                                                    <div className="social-links" onClick={()=>handleViewFilesGallery(1)}> <i className="fa fa-download"></i> <span>  Download Brochure</span></div>
                                                    {/* <DownloadFilesButton zipName={singleProduct?.name} zipBaseUrl={baseUrls?.zip_path} zipEndpoint={singleProduct?.zip_path} /> */}
                                                </div>
                                            }
                                            {/* {!isSubmitted &&
                                            <div className={`social-links mx-0${reference === true ? ' active' : ''}`} onClick={() => setReference(!reference)}>
                                                <i className="fas fa-lightbulb"></i>
                                                <span>I have a Project!</span>
                                            </div>
                                            } */}
                                        </div>
                                    {/* } */}
                                    {!isSubmitted &&
                                        <form onSubmit={handleSubmit}>

                                            <div className="comment-wrapper">
                                                {viewType !=="cardOptions" &&
                                                    <div className="d-flex gap-2 pb-2 flex-wrap">
                                                        <div className="interest-type-wrapper"><input className="form-check-input" type="radio" name={`interest_type_${singleProduct?.id}`} required value={'intreseted'} onChange={(e)=>handleInterest(e.target.value)} id={`interested_${singleProduct?.id}`} /> <label className="form-check-label" htmlFor={`interested_${singleProduct?.id}`}><i className={`${intresets === 'intreseted' ? 'fa' : 'far'} far fa-thumbs-up`}></i> <span>Interested</span> </label></div>
                                                        <div className="interest-type-wrapper"><input className="form-check-input" type="radio" name={`interest_type_${singleProduct?.id}`} required value={'love_it'} onChange={(e)=>handleInterest(e.target.value)} id={`loveit_${singleProduct?.id}`} /> <label className="form-check-label" htmlFor={`loveit_${singleProduct?.id}`}><i className={`${intresets === 'love_it' ? 'fa' : 'far'} fa-heart`}></i> <span> I Love it!</span></label></div>
                                                        <div className="interest-type-wrapper"><input className="form-check-input" type="radio" name={`interest_type_${singleProduct?.id}`} required value={'not_intreseted'} onChange={(e)=>handleInterest(e.target.value)} id={`not_interest_${singleProduct?.id}`} /> <label className="form-check-label" htmlFor={`not_interest_${singleProduct?.id}`}><i className={`${intresets === 'not_intreseted' ? 'fa' : 'far'} far fa-thumbs-down`}></i> <span> Not Interested</span></label></div>
                                                    </div>
                                                }
                                                {reference && viewType !== "cardOptions" && (
                                                    <FadeIn>
                                                        <div className="reference-projects-wrapper">
                                                            <div className="field-wrapper">
                                                                <input type="text" id={`reference1_${singleProduct?.id}`} autoComplete='off' name={`reference1_${singleProduct?.id}`} className="form-control" placeholder='Add a project name . . .' onChange={e => setReference1(e.target.value)}/>
                                                            </div>
                                                            {/* <div className="field-wrapper">
                                                                <input type="text" id={`reference2_${singleProduct?.id}`} autoComplete='off' name={`reference2_${singleProduct?.id}`} className="form-control" placeholder='Reference 2' onChange={e => setReference2(e.target.value)}/>
                                                            </div> */}
                                                            {/* <div className="reference-projects-wrapper">
                                                            <div className="field-wrapper">
                                                                <input type="text" id={`project_${singleProduct?.id}`} name={`project_${singleProduct?.id}`} className="form-control" autoFocus placeholder='Project . . .' />
                                                            </div>

                                                        </div> */}

                                                        </div>
                                                    </FadeIn>
                                                )
                                                }
                                                {/* {viewType !== 'cardOptions' &&
                                                    <div className="select-feeback-wrapper">
                                                        <Select
                                                            closeMenuOnSelect={true}
                                                            classNamePrefix='custom-input'
                                                            components={animatedComponents}
                                                            options={reqOptions}
                                                            isMulti
                                                            onChange={handleChangeRequest}
                                                            placeholder='Request . . .'
                                                        />
                                                    </div>
                                                }
                                                {viewType !== "cardOptions" &&
                                                    <div className="select-feeback-wrapper">
                                                        <Select
                                                            closeMenuOnSelect={true}
                                                            classNamePrefix='custom-input'
                                                            components={animatedComponents}
                                                            options={feedbackOptions}
                                                            isMulti
                                                            onChange={handleChangeFeedback}
                                                        />
                                                    </div>
                                                } */}
                                                {viewType === "cardOptions" && !isSubmitted &&
                                                    <>
                                                        <div className="intreset-wrapper">
                                                            <button className={`intreset-btn${intresets === 'not_intreseted' ? ' active' : ''}`} type="button" onClick={() => handleNotInterested("not_intreseted")}><i className={`${intresets === 'not_intreseted' ? 'fa' : 'far'} far fa-thumbs-down`}></i> <span> Not Interested</span></button>
                                                            <button className={`intreset-btn${intresets === 'intreseted' ? ' active intreseted' : ''}`} type="button" onClick={() => handleInterest("intreseted")}><i className={`${intresets === 'intreseted' ? 'fa' : 'far'} far fa-thumbs-up`}></i><span>Interested</span></button>
                                                            <button className={`intreset-btn${intresets === 'maybe_in_future' ? ' active love-it' : ''}`} type="button" onClick={() => handleInterest("maybe_in_future")}><span>Maybe in the future</span></button>

                                                        </div>
                                                    </>
                                                }
                                                {viewType === "cardOptions" && (intresets === 'intreseted' ) &&
                                                    <div className="require-info-wrapper">
                                                        <div className="require-info-inner-wrapper">
                                                            <div className="require-info-title-container"><span className="require-title"> Information required for</span></div>
                                                            <div className="require-info-fields-container">
                                                                <div className="require-info-filed-wrapper">
                                                                    <div className="checkbox-num-wrapper">
                                                                        <div className="checkbox-wrapper-custom">
                                                                            <input type="radio" id={`current_project_${singleProduct?.id}`} value="current_project" name={`projects_${singleProduct?.id}`} onChange={(e) => handleChangeReference(e.target.value)} />
                                                                            <label className="cbx" htmlFor={`current_project_${singleProduct?.id}`}>
                                                                                <div className="flip">
                                                                                    <div className="front"></div>
                                                                                    <div className="back">
                                                                                        <svg width="15" height="13" viewBox="0 0 16 14">
                                                                                            <path d="M2 8.5L6 12.5L14 1.5"></path>
                                                                                        </svg>
                                                                                    </div>
                                                                                </div>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <label className="req-info-label" htmlFor={`current_project_${singleProduct?.id}`}>A current project</label>
                                                                </div>

                                                                <div className="require-info-filed-wrapper">
                                                                    <div className="checkbox-num-wrapper">
                                                                        <div className="checkbox-wrapper-custom">
                                                                            <input type="radio" id={`reference_project_${singleProduct?.id}`} name={`projects_${singleProduct?.id}`} value="references" onChange={(e) => handleChangeReference(e.target.value)} />
                                                                            <label className="cbx" htmlFor={`reference_project_${singleProduct?.id}`}>
                                                                                <div className="flip">
                                                                                    <div className="front"></div>
                                                                                    <div className="back">
                                                                                        <svg width="15" height="13" viewBox="0 0 16 14">
                                                                                            <path d="M2 8.5L6 12.5L14 1.5"></path>
                                                                                        </svg>
                                                                                    </div>
                                                                                </div>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <label className="req-info-label" htmlFor={`reference_project_${singleProduct?.id}`}>Reference</label>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {/* {reference === "references" && viewType !== "cardOptions" && (intresets === 'intreseted' || intresets === 'love_it') && (
                                                    <FadeIn>
                                                        <div className="reference-projects-wrapper">
                                                            <div className="field-wrapper">
                                                                <input type="text" id='reference_1' name="reference_1" className="form-control" placeholder='Reference 1' autoFocus />
                                                            </div>
                                                            <div className="field-wrapper">
                                                                <input type="text" id="reference_2" name='reference_2' className="form-control" placeholder='Reference 2' />
                                                            </div>

                                                        </div>
                                                    </FadeIn>
                                                )
                                                } */}
                                                {reference === "current_project" && viewType === "cardOptions" &&
                                                    <FadeIn>
                                                        <div className="reference-projects-wrapper">
                                                            <div className="field-wrapper">
                                                                <input type="text" id={`project_${singleProduct?.id}`} name={`project_${singleProduct?.id}`} className="form-control" required autoFocus placeholder='Project . . .' onChange={(e)=> setReference1(e.target.value)} autoComplete='off'/>
                                                            </div>

                                                        </div>
                                                    </FadeIn>
                                                }
                                                {/* {viewType === "cardOptions" && (intresets === "intreseted" || intresets === "love_it") &&
                                                    <div className="comment-text-wrapper">
                                                        <textarea placeholder='Add a comment . . . ' rows={1} name={`comment_${singleProduct?.id}`} id={`comment_${singleProduct?.id}`} />
                                                        <div className='submit-btn-wrapper'>
                                                            <button type="submit" className="btn btn-sm">Submit</button>
                                                        </div>
                                                    </div>
                                                } */}
                                                {shouldRender &&
                                                    <div className="comment-text-wrapper">
                                                        <textarea placeholder='Add a comment . . . ' rows={1} name={`comment_${singleProduct?.id}`} id={`comment_${singleProduct?.id}`} onChange={(e) => setComment(e.target.value)}/>
                                                        <div className='submit-btn-wrapper'>
                                                            <button type="submit" className="btn">Submit</button>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </form>
                                    }
                                    {isSubmitted &&
                                        <div className="form-status-wrapper">
                                            <div className="form-status-inner-wrapper">
                                                <h4 className="form-status-title">Feedback submitted!</h4>
                                                <img src={submitted} alt={`feedback Submited ${singleProduct?.id}`} />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </FadeIn>
        </div>
    )
}

export default ProductFullCard