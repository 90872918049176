import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ProductCard from '../components/ProductCard'
import ProductCardDetail from '../components/ProductCardDetail';

// import emptyListImg from '../assets/images/emptyList.png'

import Portal from '../react-portal/Portal';
// import { Modal } from 'bootstrap';
import BackDrop from '../react-portal/BackDrop';
// import GallerySlider from '../components/gallery/GallerySlider';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../redux/features/cart/cartSlice';
import '../assets/css/CustomCheckbox.css'
import GalleryModal from '../components/galleryModal/GalleryModal';
// import ProductFullCard from '../components/ProductFullCard';
import QuickView from '../components/QuickView';
// import Select from 'react-select'
// import makeAnimated from 'react-select/animated';
import { useAddAppointmentMutation, useAddArchitectContactMutation, useAddArchitectFirmMutation, useCaptureVisitorMutation, useLazyGetAllArchitectureFirmsQuery, useLazyGetAllReprentativesQuery, useLazyGetAllRoutesQuery, useLazyGetArchitectProjectTypeQuery, useLazyGetArchitectRolesQuery, useLazyGetProductsQuery, useLazyGetRequestOptionQuery, useLazyGetSingleRepQuery, useVerifyUserMutation } from '../redux/features/product/productApi';
import { deepCopy } from '../utils/utils';
import BallLoader from '../components/loader/BallLoader';
// import logo from '../assets/images/logo-black-text.png'
import { setFeedbackUser, setFeedbackUserEmpty } from '../redux/features/feedbackuser/FeedbackUser';
import { errorMessage, successMessage } from '../components/toastMessages/ToastifyMsg';
import ButtonBallsLoader from '../components/loader/ButtonBallsLoader';
import { useParams } from 'react-router-dom';

import emptyImg from '../assets/images/empty.webp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTh, faList } from '@fortawesome/free-solid-svg-icons';
// import { setVisitorUserEmail } from '../redux/features/user/authSlice';
// import ShimmerCard from '../components/shimmer/ShimmerCard';

// const animatedComponents = makeAnimated();

// let timer;
let debounceSuggestTimer;

function Products() {
  const dispatch = useDispatch();
  // const auth = useSelector((state) => state.auth);
  const feedbackUser = useSelector(state => state.feedbackUser?.feedbackUser);

  const { referral_id } = useParams();

  const [viewType, setViewType] = useState('grid'); // for tabs which are views(1,2,3,4)
  const [quickViewModal, setQuickViewModal] = useState(false);
  const [galleryModal, setGalleryModal] = useState(false);
  const [SupplierEmailModal, SetSupplierEmailModal] = useState(false);
  const [capEmailModal, setCapEmailModal] = useState(false);
  const [modalCaptureShown, setModalCaptureShown] = useState(false);
  const [captureEmail, setCaptureEmail] = useState("");
  const [requestInfoModal, setRequestModal] = useState(false);
  const [page, setPage] = useState(1); // for pagination api call
  const [path, setPath] = useState(null); // for base urls
  const [allProducts, setAllProducts] = useState([]); // for products listing
  const [productData, setProductData] = useState(null); // to store product_ID for feedback submit
  const [hasNextPage, setHasNextPage] = useState(false);
  const [loadingMore, setLoadingMore] = useState(true);
  const [empty, setEmpty] = useState(false);
  const [currentIp, setCurrentIp] = useState(null);
  const [activeDes, setActiveDes] = useState(null); // to 
  const [verifyUserEmail, setVerifyUserEmail] = useState("");
  const [appointmentLoader, setAppointmentLoader] = useState(false);//using for verifying and also for registering api's 

  const [architectRoles, setArchitectRoles] = useState([]);
  const [allFrims, setAllFirms] = useState([]);
  // const [filteredOptions, setFilteredOptions] = useState([]);
  // const [searchValue, setSearchValue] = useState('');

  const [showSuggest, setShowSuggest] = useState(true);

  const [firmExistence, setFirmExistence] = useState(true);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firmName, setFirmName] = useState({
    value: "",
    label: "",
    id: null,
    name: ''
  });
  const [repReferral, setRepReferral] = useState("");

  // const infoModalRef  = useRef(null);


  // const [firmEmail,setFirmEmail] = useState("");
  // const [firmPhoneNumber,setFirmPhoneNumber] = useState("");
  const [selectedRoute, setSelectedRoute] = useState("");
  // const [selectedArchitectTypes,setSelectedArchitectTypes] = useState([]);
  // const [selectedRole,setSelectedRole] = useState("");

  const [submittedFeedbacks, setSubmittedFeedbacks] = useState([]);
  const [mrfLogo, setMrfLogo] = useState(null);


  // const [architectTypesOpt,setArchitectTypesOpt] = useState([]);
  const [allArchitectRoutesOpt, setAllArchitectRoutesOpt] = useState([]);
  // const [allReprentativesOpt,setAllReprentativesOpt] = useState([]);

  const [addArchitectContact] = useAddArchitectContactMutation();
  const [addArchitectFirm] = useAddArchitectFirmMutation();


  const [feedbackUserId, setFeedBackUserId] = useState(null);

  const [userReturned, setUserReturned] = useState(false);


  const feedbackOptions = useMemo(() => [
    { value: 'Not Interested', label: 'Not Interested' },
    { value: "Haven't used this product in the past", label: "Haven't used this product in the past" },
    { value: 'I use this type product on projects', label: 'I use this type product on projects' },
    { value: "Download data for reference only", label: "Download data for reference only" },
  ]
    , []);

  // const [rolesOptions,setRolesOptions] = useState([]);
  const [requestOptions, setRequestOptions] = useState([]);

  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const [errorMes, setErrorMes] = useState("");

  const [galleryIndex, setGalleryIndex] = useState(0);
  const [galleryFiles, setGalleryFiles] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryBaseUrl, setGalleryBaseUrl] = useState(null);
  const [galleryViewTab, setGalleryViewTab] = useState(null);
  const [galleryFilesEndpoint, setGalleryFilesEndpoint] = useState(null);

  const [quickView, setQuickView] = useState(null);

  const [interestType, setIntresetType] = useState(null);
  const [feedbackBody, setFeedbackBody] = useState(null);

  const [getProducts] = useLazyGetProductsQuery();

  // const [getRequestOption] = useLazyGetRequestOptionQuery();
  const [getArchitectRoles] = useLazyGetArchitectRolesQuery();
  const [getAllArchitectureFirms] = useLazyGetAllArchitectureFirmsQuery();

  // const [getAllReprentatives] = useLazyGetAllReprentativesQuery();
  // const [getArchitectProjectType] = useLazyGetArchitectProjectTypeQuery();
  const [getAllRoutes] = useLazyGetAllRoutesQuery();

  const [verifyUser] = useVerifyUserMutation();
  const [addAppointment] = useAddAppointmentMutation();

  const [getSingleRep] = useLazyGetSingleRepQuery();
  const [captureVisitor] = useCaptureVisitorMutation();

  useEffect(() => {
    try {
      const getAllproducts = async () => {
        setLoading(true);
        const referral = referral_id ? referral_id : '';
        const body = { id: page, referral };
        const { data: productsAllInfo, error } = await getProducts(body);
        if (productsAllInfo) {
          if (productsAllInfo?.status) {
            const produtsFetch = deepCopy(productsAllInfo);
            const { data, ...otherProperties } = produtsFetch?.products;
            // const newData = data.slice(1);
            setAllProducts([...allProducts, ...data]);


            setPath(produtsFetch?.paths);
            setHasNextPage(otherProperties?.next_page_url ? true : false);
          }
          if (!productsAllInfo?.status) {
            if (page === 1 && !productsAllInfo?.products) {
              setEmpty(true);
              setLoading(false);
            }
          }
          setErrorMes(false);
        }
        if (error) {
          // console.log("error", error);
          setHasError(true);
          if (error.status === "FETCH_ERROR") {
            setErrorMes("Server Not Responding.");
          }
        }
      }

      getAllproducts();
    } catch (error) {

    } finally {
      setTimeout(() => {
        setLoading(false);
        setLoadingMore(false);
      }, 4000);
    }
  }, [getProducts, page, referral_id])

  useEffect(() => {
    // if (feedbackUser) {
    setVerifyUserEmail(feedbackUser?.email ?? "");
    // }
  }, [feedbackUser])





  // useEffect(() => {
  //   try {
  //     const handleGetRequestOption = async () => {
  //       setLoading(true);
  //       const { data, error } = await getRequestOption();
  //       if (data) {
  //         const optType = deepCopy(data);
  //         let options = optType?.types.map(opt => ({
  //           value: opt?.name,
  //           label: opt?.name,
  //           id: opt?.id
  //         }));

  //         setRequestOptions(options);
  //       }
  //       if (error) {
  //         // console.log(error);
  //         setHasError(true);
  //         if (error.status === "FETCH_ERROR") {
  //           // errorMessage("Server Not Responding.");
  //           setErrorMes("Server Not Responding.");
  //         }
  //       }
  //     }
  //     handleGetRequestOption();
  //   } catch (error) {

  //   }
  //   finally {
  //     setTimeout(() => {
  //       setLoading(false);
  //       setLoadingMore(false);
  //     }, 3000);
  //   }


  // }, [getRequestOption]);

  useEffect(() => {
    try {
      const getAllArchitectRoles = async () => {
        const res = await getArchitectRoles();
        if (res?.data) {
          if (res?.data?.status) {
            const arcRoles = deepCopy(res?.data);
            let rolesOpt = arcRoles?.roles.map(role => ({
              value: role?.name,
              label: role?.name,
              id: role?.id
            }));
            setArchitectRoles(rolesOpt);
          }
        }
        if (res?.error) {
          console.log("error getArchitectRoles", res?.error);
        }

      }
      setTimeout(() => {
        getAllArchitectRoles();
      }, 3000);
    } catch (error) {

    }

  }, [getArchitectRoles]);

  useEffect(() => {
    try {
      const getAllFirms = async () => {
        const res = await getAllArchitectureFirms();
        if (res?.data) {
          if (res?.data?.status) {
            const allFir = deepCopy(res?.data);
            let opt = allFir?.architects.map(firm => ({
              value: firm?.name,
              label: firm?.name,
              id: firm?.id,
              name: firm?.name
            }));
            setAllFirms(opt);
            // setFilteredOptions(opt.slice(0, 60));
          }
        }
        if (res?.error) {
          console.log("errorAllArchitectureFirms", res?.error);
        }
      }
      setTimeout(() => {
        getAllFirms();
      }, 5000);
    } catch (error) {

    }
  }, [getAllArchitectureFirms]);

  // useEffect(() => {
  //   try {
  //     const getAllReps = async()=>{
  //         try {
  //           const resp = await getAllReprentatives();
  //           if(resp?.data){
  //             if(resp?.data?.status){
  //               const  allRepsResp = deepCopy(resp?.data);
  //               let reps = [{
  //                 value: "I don't know yet." ,
  //                 label: "I don't know yet.",
  //                 id: "",
  //               },...allRepsResp?.reps.map(rep=>({
  //                 value: rep?.name,
  //                 label: rep?.name,
  //                 id: rep?.id
  //               }))]
  //               // reps = allRepsResp?.reps.map(rep=>({
  //               //   value: rep?.name,
  //               //   label: rep?.name,
  //               //   id: rep?.id
  //               // }))
  //               setAllReprentativesOpt(reps);
  //             }
  //             if (resp?.error) {
  //               console.log((resp?.error),"Error in getting reps");
  //             }
  //           } 
  //         } catch (error) {

  //         }
  //     }
  //     setTimeout(() => {
  //       getAllReps();
  //     }, 16000);

  //   } catch (error) {

  //   }


  // }, [getAllReprentatives])

  // for representative Referral

  useEffect(() => {
    if (referral_id) {
      // console.log("useEffect  referral_id", referral_id);
      const getRep = async () => {
        try {
          const resp = await getSingleRep(referral_id);

          if (resp?.data) {
            if (resp?.data?.status) {
              const repData = deepCopy(resp?.data?.reps)
              setRepReferral(repData?.id);
            }
          }
          if (resp?.error) {
            console.log("Error in fetching single Rep : ", resp.error);
          }
        } catch (error) {

        }

      }
      getRep();
    }


  }, [referral_id, getSingleRep])


  // const handleSearch = (newValue) => {
  //   setSearchValue(newValue);
  //   if (newValue.trim()) {
  //     setFilteredOptions(allFrims.filter((option) =>
  //       option.label.toLowerCase().includes(newValue.toLowerCase())
  //     ));
  //   }
  //   if (!newValue.trim()) {
  //       setFilteredOptions(allFrims.slice(0, 60))
  //   }
  // };

  // useEffect(()=>{
  //   try {
  //       const handleAllArchitectTypes = async() => {
  //         const res = await getArchitectProjectType();
  //           if (res?.data) {
  //               if (res?.data?.status) {
  //                 const allArcType = deepCopy(res?.data);
  //                 let opt = allArcType?.types.map(type=>({
  //                   value: type?.name,
  //                   label: type?.name,
  //                   id: type?.id
  //                 }));
  //                 setArchitectTypesOpt(opt);
  //               }
  //               if (!res?.data?.status) {

  //               }
  //           }
  //           if (res?.error) {
  //               console.log("ErrorGetArchitectProjectType>>",res?.error)
  //           }
  //       }
  //       handleAllArchitectTypes()

  //   } catch (error) {

  //   }
  // },[getArchitectProjectType]);

  useEffect(() => {
    try {
      const handleAllRoutes = async () => {
        const res = await getAllRoutes();
        if (res?.data) {
          if (res?.data.status) {
            const allRoutesData = deepCopy(res?.data);
            // console.log("allRoutesData>>",allRoutesData)
            let opt = allRoutesData?.routes.map(route => ({
              value: route?.name,
              label: route?.name,
              id: route?.id,
              region: route?.region
            }));
            setAllArchitectRoutesOpt(opt);
            setSelectedRoute(opt[0]);
          }
        }
        if (res?.error) {
          console.log("errorAllRoutesData>>", res?.error);
        }
      }
      handleAllRoutes();
    } catch (error) {

    }
  }, [getAllRoutes])



  // useEffect for capturing email once
  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (allProducts.length > 0 && window.scrollY > 300 && !modalCaptureShown && !captureEmail) {
  //       setCapEmailModal(true);
  //       setModalCaptureShown(true); // Set modalCaptureShown to true once modal is shown
  //       window.removeEventListener('scroll', handleScroll);
  //     }
  //   };

  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, [allProducts, modalCaptureShown]);
  // =============================================================
  // useEffect(() => {
    //   const handleTimeout = () => {
      //     if (allProducts.length > 0 && !modalCaptureShown && !captureEmail) {
        //       setCapEmailModal(true);
        //       setModalCaptureShown(true); // Set modalCaptureShown to true once modal is shown
        //     }
        //     // if (allProducts.length > 0 && captureEmail && !modalCaptureShown) {
          //     //   setModalCaptureShown(true);
          //     //   setUserReturned(true);
          //     //   setTimeout(() => {
            //     //     setUserReturned(false);
            //     //   }, 3000);
  //     // }
  //   };
  
  //   const timer = setTimeout(handleTimeout, 500); // 1000 ms = 1 seconds
  
  //   return () => {
    //     clearTimeout(timer); // Clean up the timeout on component unmount
    //   };
    // }, [allProducts, modalCaptureShown, captureEmail]);
    // =============================================================
    
  // useEffect(() => {
  //   if (auth?.user) {
  //     setViewType('card')
  //   }
  //   else {
  //     if (viewType !== "list" && viewType !== "grid" && viewType !== "cardOptions") {
  //       setViewType('list')
  //     }
  //   }
  // }, [auth])

  //choose the screen size 
  const handleResize = useCallback(() => {
    if (window.innerWidth <= 768) {
      if (viewType !== 'grid') {
        setViewType('grid');
      }
      setGalleryModal(false);
    }
    // else {
    //   if (viewType !== 'list' && viewType !== 'card' && viewType !== 'grid' && viewType !== 'cardOptions') {
    //     setViewType('list');
    //     setQuickViewModal(false);
    //   }
    // }
  }, [viewType]);

  // Debounce function to delay calls to handleResize
  const debouncedHandleResize = useCallback(
    () => {
      const timeoutId = setTimeout(() => {
        handleResize();
      }, 250); // Delay execution by 250 milliseconds

      return () => clearTimeout(timeoutId); // Clear timeout if handleResize is called again within the delay
    },
    [handleResize]
  );

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", debouncedHandleResize);
    return () => window.removeEventListener('resize', debouncedHandleResize);
  })

  useEffect(() => {
    handleResize();
  }, []) // eslint-disable-line 

  useEffect(() => {
    // Add event listener for beforeunload event
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  // Function to handle beforeunload event
  const handleBeforeUnload = () => {
    dispatch(setFeedbackUserEmpty());
  };

  const handleCloseQuickView = useCallback(() => {
    setQuickViewModal(false);
  }, [])

  const handleCloseEmailModal = useCallback(() => {
    SetSupplierEmailModal(false);
  }, [])

  const handleOpenEmailModal = async (id, interest, feedBody) => {
    setProductData(id);
    setIntresetType(interest);
    setFeedbackBody(feedBody);
    // console.log("handleOpenEmailModal");
    if (feedbackUser) {
      // console.log("feedbackUser::",feedbackUser)
      // console.log("feedbackUser")
      handleVerifyUser(id, interest, feedBody);
      successMessage("Saving your Feedback.");
    }
    if (!feedbackUser) {
      // console.log("!feedbackUser")
      if (captureEmail) {
        successMessage("Saving your Feedback.");
        setVerifyUserEmail(captureEmail);
        setEmail(captureEmail);
        handleVerifyUser(id, interest, feedBody, captureEmail);
      }
      if (!captureEmail) {
        SetSupplierEmailModal(true);

      }
    }
  }



  const handleQuickView = (data) => {
    setQuickView(data);
    setQuickViewModal(true);
  }

  const handleAddToCart = useCallback(({ id, title, image }) => {
    dispatch(addToCart({ id, title, image, quantity: 1 }));
  }, []);

  const handleCloseGallery = () => {
    setGalleryModal(false);
    // setGalleryImages([]);
  }

  const handleGalleryImagesModal = (images, index = 0, mrLogo, files, viewMode, fileEndpoint) => {
    //viewmode=> 0=images, 1=Documents, 2=videos
    // if (!captureEmail) setCapEmailModal(false); setCapEmailModal(true); 
    // if (captureEmail) {
      setGalleryImages(images);
      setGalleryIndex(index);
      setMrfLogo(mrLogo);
      setGalleryFiles(files);
      setGalleryModal(true);
      setGalleryBaseUrl(path);
      setGalleryViewTab(viewMode);
      setGalleryFilesEndpoint(fileEndpoint);
    // }
   }

  const getIpAddress = async () => {
    let ip_address = await fetch('https://api.ipify.org?format=json')
      .then((res) => res.json())
      .catch((err) => { console.error(`Error getting IP Address: ${err}`) })
    return ip_address?.ip;
  }

  const handleCreateAppointment = async (userData, productId, interest, feedBody) => {
    try {
      // console.log("handleCreateAppointment")
      let ipaddress = currentIp || await getIpAddress();
      if (!currentIp) setCurrentIp(ipaddress);

      // console.log("interestType>>",interestType,"interest>>",interest);

      let view = window.innerWidth <= 768 ? 'Mobile' : 'Desktop';
      const data = {
        product_id: productId,
        contact_id: userData?.id,
        call_type: view,
        ip_address: ipaddress,
        review: interest,
        rep_id: repReferral,
      }

      const body = { ...data, ...feedBody }
      const appointmentResponse = await addAppointment(body);
      if (appointmentResponse?.data) {
        if (appointmentResponse?.data?.status) {
          // successMessage(appointmentResponse?.data?.message);
          setSubmittedFeedbacks((prevState) => [...prevState, { id: productId, feedbacktype: interest }]);
          setFirmName({
            value: "",
            label: "",
            id: null,
            name: ""
          })
          // handleCloseEmailModal();
          //  handleResetEmailTimer();
          setRequestModal(false);
        }
        if (!appointmentResponse?.data?.status) {
          errorMessage(appointmentResponse?.data?.message);
        }
      }
      if (appointmentResponse?.error) {
        errorMessage(appointmentResponse?.error?.data?.message);
        // console.log("appointmentResponse>",appointmentResponse?.error);
        setAppointmentLoader(false);
      }

    } catch (error) {

    }
    finally { }
  }

  // verify Email
  const handleSubmitVerifyEmail = async (e) => {
    try {
      e.preventDefault();
      setAppointmentLoader(true);
      // console.log("productData>>",productData);
      // console.log("handleSubmitVerifyEmail>",verifyUserEmail);
      if (verifyUserEmail.trim() && productData) {
        setCaptureEmail(verifyUserEmail)
        await handleVerifyUser(productData, interestType, feedbackBody);
      }
      if (!verifyUserEmail.trim()) {
        errorMessage("Enter email to continue.");
      }

    } catch (error) {

    }
    finally { setAppointmentLoader(false) }
  }

  // const handleResetEmailTimer = ()=>{
  //   clearTimeout(timer);
  //   timer = setTimeout(() => {
  //     dispatch(setFeedbackUserEmpty());
  //     successMessage("Email has been reset.");
  //   }, 5 * 60 * 1000);
  // }

  const handleVerifyUser = async (id, interest, feedBody, emailAddress = verifyUserEmail) => {
    try {

      const body = {
        email: emailAddress,
        prod_id: id
      }
      setEmail(verifyUserEmail);
      // console.log("handleVerifyUser->>body",body)
      const verifyResponse = await verifyUser(body);

      if (verifyResponse?.data) {
        // console.log("data:",verifyResponse?.data);
        if (verifyResponse?.data?.status) {

          if (!verifyResponse?.data?.appointment_existed) {
            // console.log("appointment doesn't exists")
            const userData = await deepCopy(verifyResponse?.data);
            dispatch(setFeedbackUser(userData?.user));
            setFeedBackUserId(userData?.user);
            await handleCreateAppointment(userData?.user, id, interest, feedBody);
            handleCloseEmailModal();
          }
          if (verifyResponse?.data?.appointment_existed) {
            // console.log("appointment_existed");
            const userData = await deepCopy(verifyResponse?.data);
            // setSubmittedFeedbacks((prevState)=> { console.log("productData->prevState::",productData); return[...prevState,{id:productData,feedbacktype:''} ]});
            // console.log("dispatch-setFeedbackUser",userData);
            dispatch(setFeedbackUser(userData?.user));
            setFeedBackUserId(userData?.user);
            setSubmittedFeedbacks((prevState) => [...prevState, { id, feedbacktype: `${userData?.review_type}` }]);
            handleCloseEmailModal();
            successMessage("Feedback already submitted.");
            // handleResetEmailTimer();            
          }
        }
        if (!verifyResponse?.data?.status) {
          handleCloseEmailModal();
          setRequestModal(true);
          setEmail(emailAddress);
          // errorMessage("Under Development for New User.");
          errorMessage("Please register as a new user.");

        }
      }
      if (verifyResponse?.error) {
        errorMessage(verifyResponse?.error?.data?.message);
      }

    } catch (error) {

    }
  }




  const handleSubmit = (e) => {
    e.preventDefault();
    handleOpenEmailModal();
  }

  const handleCreateArchitectureContact = async (firmId) => {
    try {

      let body = {
        full_name: firstName,
        // last_name: lastName,
        email,
        phone_number: phoneNumber,
        role_id: architectRoles[0].id,
        architect_id: firmId,
      }

      // console.log("createArcContact-FirmId>>",firmId)
      // console.log("handleCreateArchitectureContact-body>>",body);
      const resp = await addArchitectContact(body);

      if (resp?.data) {
        if (resp?.data?.status) {
          const feedBackUserData = deepCopy(resp?.data);
          dispatch(setFeedbackUser(feedBackUserData?.contact));
          if (productData) {
            // console.log("productData --- handleCreateArchitectureContact",productData)
            await handleCreateAppointment(feedBackUserData?.contact, productData, interestType, feedbackBody);
          }
          if (!productData) {
            // console.log("!productData --- handleCreateArchitectureContact",productData)
            setAppointmentLoader(false);
            setRequestModal(false);
          setModalCaptureShown(true);
          }
        }
        if (!resp?.data?.status) {
          console.log("!addArchitectContact>", resp?.data?.message);
          errorMessage(resp?.data?.message);
        }
      }
      if (resp?.error) {
        console.log("error-addArchitectContact", resp?.error);
        errorMessage(resp?.error?.data?.message);
      }
    } catch (error) {

    }
  }

  const handleCreateArchitectureFirm = async () => {
    try {
      if (productData) {
        
        successMessage("Saving your Feedback.");
      }
      // architects/add_architect_firm
      const body = {
        firm_name: firmName?.name,
        company_email: email,
        // phone_number: firmPhoneNumber,
        // route_id: "",
        architect_type: "",
        route_id: selectedRoute?.id,
        // architect_type: selectedArchitectTypes?.id
      }
      // console.log("!firmExistence",body);

      const resp = await addArchitectFirm(body);
      if (resp?.data) {
        if (resp?.data?.status) {
          // console.log(resp?.data);
          await handleCreateArchitectureContact(resp?.data?.firm?.id);
        }
        if (!resp?.data?.status) {
          errorMessage(resp?.data?.message);
          console.log("!addArchitectFirm", resp?.data);
        }
      }
      if (resp?.error) {
        console.log("error-addArchitectFirm", resp?.error);
        errorMessage(resp?.error?.data?.message);
      }
    } catch (error) {

    }
  }

  const handleSubmitFullInfo = async (e) => {
    try {
      e.preventDefault();
      setAppointmentLoader(true);
      // setRequestModal(false);
      // if (firmExistence) {
      //   await handleCreateArchitectureContact(firmName?.id);
      // }
      // if (!firmExistence) {
        await handleCreateArchitectureFirm();
      // }
    } catch (error) {

    } finally {
      setAppointmentLoader(false);
      // setRequestModal(false);
      setFirmExistence(true);
    }

  }

  const loadMore = () => {
    setLoading(true);
    setLoadingMore(true);
    setPage(prev => prev + 1);
    setHasNextPage(false);
  }

  const handleChangeView = (view) => {
    setLoading(true);
    if (!empty) {
      setViewType(view);
      setLoadingMore(true);
      if (page !== 1) {
        setHasNextPage(true);
        setLoadingMore(false);
        setPage(1);
        setAllProducts([]);
      } else {

        setTimeout(() => {
          setLoading(false);
          setLoadingMore(false);
        }, 1000);
      }
    }
    if (empty) {
      setEmpty(false);
      setViewType(view);
      setTimeout(() => {
        setLoading(false);
        setEmpty(true);
      }, 1000);
    }
  }

  const handleActiveDes = (id) => {
    setActiveDes(id);
  }

  // const handleChangeRole = (option)=>{
  //   setSelectedRole(option);
  // }

  // const handleChangeFirm = (option) => {
  //   setFirmName(option);
  // }

  // const handleChangeRoute = (option) => {
  //   setSelectedRoute(option);
  // }

  // const handleChangeArchitectType = (option) => {
  //   setSelectedArchitectTypes();
  // }


  // const handleChangeFirmExist = (value)=>{
  //   setFirmExistence(value);
  //   setFirmName("");
  // }

  //=========FRIM NAME Suggestion START========== 
  const [suggestList, setSuggestList] = useState([]);

  const autoSuggestion = async (e) => {
    e.preventDefault();
    clearTimeout(debounceSuggestTimer);
    debounceSuggestTimer = setTimeout(() => {
      // Your actual autoSuggestion logic goes here
      try {
        let searchFirm = e.target.value;
        if (searchFirm !== "" && searchFirm !== " ") {
          const filteredFirms = allFrims.filter(firm => firm?.name.toLowerCase().match(searchFirm.toLowerCase()));
          if (filteredFirms.length > 0) {
            let list = deepCopy(filteredFirms);
            setSuggestList(list);
            setShowSuggest(true);
            setFirmExistence(true);
          }
          else {
            setFirmExistence(false);
            setShowSuggest(false);
            setFirmName({
              ...firmName,
              value: searchFirm,
              name: searchFirm,
            })
          }
        } else {
          setSuggestList([]);
        }

      } catch (error) {

      }
    }, 300);
  };



  const fillFields = async (name) => {
    // e.preventDefault();
    try {
      setShowSuggest(false);
      setFirmName({
        value: name?.name,
        label: name?.name,
        id: name?.id,
        name: name?.name,
      })
    } catch (error) {
    }
  }

  const handleChangeFrimInfo = (e) => {
    // console.log("handleChangeFrimInfo>",e.target.value)
    e.preventDefault();
    setFirmName({
      value: "",
      label: "",
      id: null,
      name: e.target.value,
    })
  }

  const handleSubmitCaptureEmail = async (e) => {
    e.preventDefault();
    try {
      setAppointmentLoader(true);
      let ipaddress = await getIpAddress();
      setCurrentIp(ipaddress);
      let body = {
        email: captureEmail,
        ip_address: ipaddress
      }

      const { data, error } = await captureVisitor(body);
      if (data) {
        if (data?.status) {
          await handleCheckVisitor(captureEmail)
        }
        if (!data?.status) {
          errorMessage("Something went wrong. Submit again.")
          setTimeout(() => {
            setAppointmentLoader(false);
          }, 2000);
        }
      }
      if (error) {
        console.log("error", error)
        errorMessage("Something went wrong. Submit again.")
        setTimeout(() => {
          setAppointmentLoader(false);
        }, 2000);
      }

    } catch (err) {
      console.log("err", err)
    }
    finally {
     
    }
  }

  // dispatch(setVisitorUserEmail(captureEmail));
          
  // setModalCaptureShown(true);

  const handleCheckVisitor = async (user_email) => {
    try {
      
      const body = {
        email: user_email,
      }
      setEmail(user_email);
      setVerifyUserEmail(user_email);
      
      const verifyResponse = await verifyUser(body);
      
      if (verifyResponse?.data) {
        if (verifyResponse?.data.status) {
          setCapEmailModal(false);
          // dispatch(setVisitorUserEmail(captureEmail));
          setModalCaptureShown(true);
          setUserReturned(true);
          setTimeout(() => {
            setUserReturned(false);
          }, 2000);
        }
        if (!verifyResponse?.data.status) {
          errorMessage("Please register as a new user.");
          setRequestModal(true);
          setCapEmailModal(false);
        }
        
          // console.log("verifyResponse->data::",verifyResponse?.data);
      }
      if (verifyResponse?.error) {
        errorMessage(verifyResponse?.error.data.message);
        console.log("verifyResponse->error::",verifyResponse?.error);
      }
    } catch (err) {
      console.log("catch err",err);
    }
    finally{
        setAppointmentLoader(false);
      
    }
  }
  



  return (
    <>
      {/* <section className="banner-wrapper">
        <div className="banner-inner-wrapper" style={{ 'backgroundImage': "url('https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/98473250460307.5e03e6e44badb.jpg')", 'height': '400px', 'backgroundPosition': 'center', 'backgroundSize': 'cover' }}>
          <div className="banner-inner-text">
            <FadeIn>
              <h2>Products</h2>
            </FadeIn>
          </div>
          <div className="overlay"></div>
        </div>
      </section> */}
      <div className="product-wrapper">
        <div className="container-fluid container-md">
          <div className="product-inner-wrapper">
            <div className="product-header-wrapper">
              <div className="product-header-inner-wrapper">
                <div className="product-view-type">
                  {allProducts.length > 0 &&
                    <>
                      <button type='button' className={`view ${viewType === 'grid' ? 'active cursor-disable' : ''}`} disabled={viewType === 'grid' ? true : false} onClick={() => handleChangeView('grid')}>
                        <FontAwesomeIcon icon={faTh} />
                        {/* <i className="fas fa-th"></i> */}
                        <span>Overview</span>
                      </button>
                      <button type='button' className={`view ${viewType === 'list' ? 'active cursor-disable' : ''}`} disabled={viewType === 'list' ? true : false} onClick={() => handleChangeView('list')}>
                        <FontAwesomeIcon icon={faList} />
                        {/* <i className="fas fa-list"></i> */}
                        <span>Detailed</span>
                      </button>
                    </>
                  }
                  {/* {auth?.user &&
                    <button type='button' className={`view ${viewType === 'card' ? 'active cursor-disable' : ''}`} disabled={viewType === 'card' ? true : false} onClick={() => handleChangeView('card')}><i className="fas fa-square-full"></i> <span>View 3</span></button>
                  } */}
                  {/* <button type='button' className={`view ${viewType === 'cardOptions' ? 'active cursor-disable' : ''}`} disabled={viewType === 'cardOptions' ? true : false} onClick={() => handleChangeView('cardOptions')}><i className="fas fa-square-full"></i> <span>View 3</span></button> */}

                </div>
                {/* <div className="product-filter-wrapper d-flex gap-2">
                  <div className="select-filter-wrapper">
                    <select>
                      <option value="">September 2023</option>
                      <option value="">October 2023</option>
                      <option value="">November 2023</option>
                      <option value="">December 2023</option>
                    </select>
                  </div>
                  <div className="search-filter-wrapper">
                    <input type='search' placeholder='Search' name='search' id='search' ></input>
                  </div>
                </div> */}
              </div>
            </div>
            {/* {loading ? <div className="balls-loader-wrapper"><BallLoader /></div>} : */}

            <div className="products-body-wrapper">
              <div className="products-body-inner-wrapper">
                {/* View 1 */}
                {viewType === 'grid' &&
                  <>
                    {allProducts.length > 0 &&
                      allProducts?.map(product => {
                        return (
                          <ProductCard product={product} paths={path} key={product.id} viewType='grid' activeDes={activeDes} handleActiveDes={handleActiveDes} submittedFeedbacks={submittedFeedbacks} handleOpenEmailModal={handleOpenEmailModal} handleAddToCart={handleAddToCart} handleQuickView={handleQuickView} handleGalleryImagesModal={handleGalleryImagesModal} />
                        );
                      })
                    }
                  </>

                }
                {/* View 2 */}
                {viewType === 'list' && (
                  <>
                    {allProducts.length > 0 &&
                      allProducts?.map(product => {
                        return (
                          <ProductCardDetail product={product} paths={path} key={product.id} requestOptions={requestOptions} submittedFeedbacks={submittedFeedbacks} feedbackOptions={feedbackOptions} handleAddToCart={handleAddToCart} handleQuickView={handleQuickView} handleOpenEmailModal={handleOpenEmailModal} handleGalleryImagesModal={handleGalleryImagesModal} />
                        );
                      })

                    }
                  </>
                )
                }
                {/* View 3 */}
                {/* {viewType === 'card' &&
                  (<>
                    {allProducts.length > 0 &&
                      allProducts.map(product => {
                        return (
                          <ProductFullCard product={product} paths={path} key={product.id} viewType={'card'} requestOptions={requestOptions} submittedFeedbacks={submittedFeedbacks} feedbackOptions={feedbackOptions} handleAddToCart={handleAddToCart} galleryImagesArr={galleryImages} handleOpenEmailModal={handleOpenEmailModal} handleGalleryModal={handleGalleryImagesModal} />
                        )
                      })
                    }
                  </>
                  )
                } */}
                {/* View 4 */}
                {/* {viewType === 'cardOptions' &&
                  (<>
                    {allProducts.length > 0 &&
                      allProducts.map(product => {
                        return (
                          <ProductFullCard product={product} paths={path} key={product.id} requestOptions={requestOptions} submittedFeedbacks={submittedFeedbacks} feedbackOptions={feedbackOptions} viewType={'cardOptions'} handleAddToCart={handleAddToCart} galleryImagesArr={galleryImages} handleOpenEmailModal={handleOpenEmailModal} handleGalleryModal={handleGalleryImagesModal} />
                        )
                      })
                    }
                  </>
                  )
                } */}
                {/* {viewType === 'grid' && loading &&(
                  <>
                    <ShimmerCard/>
                  </>
                )

                } */}

                {loading && <div className="balls-loader-wrapper"><BallLoader /></div>}

                {!loading && hasNextPage && <div className="col-12 load-more-wrapper"> <button type='button' className="btn btn-load-more" onClick={() => loadMore()}>Load More</button> </div>}
              </div>
            </div>
            {empty && (
              <>
                {/* <div className="empty-list text-center">
                  <h3><i> No active listing found!</i></h3>
                </div> */}
                <div className="notfound-wrapper" style={{ height: '70vh' }}>
                  <div className='text-center'>
                    <img src={emptyImg} alt="empty" />
                  </div>
                  <div className="notfound">
                    <div className="notfound-404">
                      <h1 style={{ color: '#707273' }}>Oops!</h1>
                      <h2>{referral_id ? `No active product found against ${referral_id}.` : "No active enquiry list found!"} </h2>
                    </div>
                    {/* <Link to='/'>Go TO Homepage</Link> */}
                  </div>
                </div>

              </>
            )}
            {hasError &&
              (
                <div className="notfound-wrapper">
                  <div className="notfound">
                    <div className="notfound-404">
                      <h1>Oops!</h1>
                      <h2>{errorMes}</h2>
                    </div>
                    {/* <Link to='/'>Go To Homepage</Link> */}
                  </div>
                </div>
              )
            }
            {/* } */}
          </div>
        </div>
      </div>
      {quickViewModal && (
        <Portal>
          <BackDrop handleClose={handleCloseQuickView}>
            <QuickView imgArry={galleryImages} handleSubmit={handleSubmit} />
          </BackDrop>
        </Portal>)
      }

      {galleryModal && (
        <Portal>
          <GalleryModal handleClose={handleCloseGallery} path={path} galleryIndex={galleryIndex} galleryImages={galleryImages} mrfLogo={mrfLogo} galleryFiles={galleryFiles} galleryView={galleryViewTab} galleryFilesEndpoint={galleryFilesEndpoint} />
        </Portal>)
      }
      {SupplierEmailModal && (
        <Portal>
          <BackDrop backDropDisable={true}>
            <>
              <div className="supplier-email-wrapper">
                <form onSubmit={handleSubmitVerifyEmail}>
                  {/* <input type="hidden" id="ipFormInput" name="ipAddress"/> */}
                  <div className="field-wrapper">
                    <label className='form-label' htmlFor="supplier_email">Company Email</label>
                    <input type="email" id="supplier_email" name="supplier_email" placeholder='abc@gmail.com' defaultValue={verifyUserEmail} autoFocus required className='form-control' onChange={(e) => setVerifyUserEmail(e.target.value)} />
                    <p className='mt-2 mb-0 opacity-75'><strong> Your email lets us save your preferences!</strong></p>
                    {/* <p className='mt-2 mb-0'> <strong> By providing your email, you allow us to save your preferences!</strong></p> */}
                  </div>

                  {/* <div className="field-wrapper">
                    <label className='form-label' htmlFor="architectural_firm"> Architectural Firm</label>
                    <input type="text" id="architectural_firm" name="architectural_firm" required className='form-control' />
                  </div> */}
                  <div className="submit-btn-wrapper pt-1">
                    <button type='submit' className="btn" disabled={appointmentLoader}>{appointmentLoader ? <ButtonBallsLoader /> : 'Submit'} </button>
                  </div>
                </form>
              </div>

            </>
          </BackDrop>
        </Portal>
      )}
      {capEmailModal &&
        <Portal>
          <BackDrop backDropDisable={true}>
            <>
              <div className="supplier-email-wrapper">
                <div className='visiting-title-container'>
                  <h2>Thank you for visiting my page.</h2>
                  <p>To quickly process your request for products and <br />samples, please share your email address.</p>
                </div>
                <form onSubmit={handleSubmitCaptureEmail}>
                  {/* <input type="hidden" id="ipFormInput" name="ipAddress"/> */}
                  <div className="field-wrapper">
                    <label className='form-label' htmlFor="visitor_email">Email Address</label>
                    <input type="email" id="visitor_email" name="visitor_email" placeholder='abc@gmail.com' defaultValue={captureEmail} autoFocus required className='form-control' onChange={(e) => setCaptureEmail(e.target.value)} />
                    {/* <p className='mt-2 mb-0 opacity-75'><strong> Your email lets us save your preferences!</strong></p> */}
                    {/* <p className='mt-2 mb-0'> <strong> By providing your email, you allow us to save your preferences!</strong></p> */}
                  </div>

                  {/* <div className="field-wrapper">
                  <label className='form-label' htmlFor="architectural_firm"> Architectural Firm</label>
                  <input type="text" id="architectural_firm" name="architectural_firm" required className='form-control' />
                </div> */}
                  <div className="submit-btn-wrapper pt-2">
                    <button type='submit' className="btn w-100" disabled={appointmentLoader}>{appointmentLoader ? <ButtonBallsLoader /> : 'Continue'} </button>
                  </div>
                </form>
              </div>

            </>
          </BackDrop>
        </Portal>
      }

      {requestInfoModal && (
        <Portal>
          {/* title={'Request Further Information'} */}
          <BackDrop backDropDisable={true} >
            <div className="further-info-wrapper">
              {/* col-sm-12 col-md-6 col-12 */}
              {/* <h4>Request Further Information</h4> */}
              {/* <div className="brand-wrapper">
                  <div className="brand-inner-wrapper">
                    <img src={logo} alt="logo" /> 
                    <h2 className="brand-title">Feature Products</h2>
                  </div>
              </div> */}
              <div className='field-wrapper py-3'>
                <span>It looks like you're new to my page. To help me process your requests, please provide a bit more information.</span>
              </div>
              <form onSubmit={handleSubmitFullInfo}>


                <div className="field-wrapper col-12">
                  <label className='form-label' htmlFor="full_name"> Full Name<span className='text-danger'>*</span></label>
                  <input type="text" id="full_name" name="full_name" required autoFocus className='form-control' onChange={(e) => setFirstName(e.target.value)} placeholder='Enter Full Name'/>
                </div>

                {/* <div className="field-wrapper col-12">
                  <label className='form-label' htmlFor="last_name"> Last Name<span className='text-danger'>*</span></label>
                  <input type="text" id="last_name" name="last_name" required className='form-control' onChange={(e) => setLastName(e.target.value)} />
                </div> */}


                <div className="field-wrapper col-12">
                  <label className='form-label' htmlFor="email_add"> Email Address<span className='text-danger'>*</span></label>
                  <input type="email" id="email_add" name="email_add" required className='form-control' readOnly={email.trim() ? true : false} disabled={email.trim() ? true : false} defaultValue={email} onChange={(e) => setEmail(e.target.value)} />
                </div>

                {/* <div className="field-wrapper col-12">
                    <label className='form-label' htmlFor="role"> Role<span className='text-danger'>*</span></label>
                    <Select
                      closeMenuOnSelect={true}
                      classNamePrefix='custom-input'
                      components={animatedComponents}
                      options={architectRoles}
                      // isMulti
                      onChange={handleChangeRole}
                      placeholder='Owner/Principal'
                      name='role'
                      id='role'
                      required
                    />
                  </div> */}

                <div className="field-wrapper col-12">
                  <label className='form-label' htmlFor="phone_number">  Phone Number<span className='text-danger'>*</span></label>
                  <input type="tel" id="phone_number" name="phone_number" required defaultValue={phoneNumber} className='form-control' onChange={(e) => setPhoneNumber(e.target.value)} placeholder='Enter Phone Number'/>
                </div>
                {/* <div className="field-wrapper col-12">
                  <label className='form-label' htmlFor="preferred_time">  Preferred Time<span className='text-danger'>*</span></label>
                  <input type="datetime-local" id="preferred_time" name="preferred_time"  required className='form-control' 
                  onChange={(e)=> setPhoneNumber(e.target.value)}
                  />
                </div> */}

                {/* <div className="custom-divider"><hr /></div> */}
                {/* <div className="field-wrapper col-12 firm-status-wrapper ">
                  <label className='form-label  mb-0'>Firm Information</label>
                  <div className="existing-firm px-3">
                    <input type="radio" name='firm_status' required id="existing_firm" checked={firmExistence} onChange={(e) => handleChangeFirmExist(true)} /> <label htmlFor='existing_firm'>Search</label>
                  </div>
                  <div className="existing-firm">
                    <input type="radio" name='firm_status' required id="new_firm" checked={!firmExistence} onChange={(e) => handleChangeFirmExist(false)} /> <label htmlFor='new_firm'>New</label>
                  </div>
                </div>  */}
                <div className="d-flex flex-wrap col-12 px-1">
                  {/* nameSuggest */}
                  <div className="field-wrapper col-12  px-0">
                    <label className='form-label' htmlFor="firm_name"> Firm Name<span className='text-danger'>*</span></label>
                    <input type="text" id="firm_name" name="firm_name" autoComplete='off' placeholder='Enter Firm Name' 
                    //  placeholder='Search by firm name . . .'
                      value={firmName?.name}
                      className='form-control'
                      required
                      spellCheck={false}
                      onChange={(e) => handleChangeFrimInfo(e)}
                      // onKeyUp={(e) => autoSuggestion(e)}
                      // onBlur={() => {
                      //   setTimeout(() => {
                      //     setShowSuggest(false)
                      //   }, 200)
                      // }}
                      // onFocus={(e) => autoSuggestion(e)}
                    />
                    {/* {showSuggest && (suggestList.length > 0) && (
                      <div className="autosuggestDropDownContainer">
                        <div className="autosuggestDropDown">
                          {
                            <ul>
                              {suggestList.length && (
                                suggestList?.map((list, index) =>
                                  <li key={index} onClick={() => fillFields(list)}>
                                    {list.name}
                                  </li>)
                              )}
                            </ul>
                          }
                        </div>
                      </div>
                    )} */}
                  </div>
                </div>
                    {/* <Select
                            closeMenuOnSelect={true}
                            classNamePrefix='custom-input'
                            components={animatedComponents}
                            // options={allFrims}
                            // isMulti
                            onChange={handleChangeFirm}
                            options={filteredOptions}
                            onInputChange={handleSearch}
                            placeholder='Select Architectureal Firm'
                            name='architecture_Firm'
                            required
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                width: '100%',
                              }),
                            }}
                          /> */}

                {!firmExistence && (
                  <>
                    {/* <div className="field-wrapper col-12">
                      <label className='form-label' htmlFor="firm_name"> Firm Name<span className='text-danger'>*</span></label>
                      <input type="text" id="firm_name" name="firm_name"  defaultValue={firmName?.name} required onChange={(e) => handleChangeFrimInfo(e)} className='form-control' />
                    </div> */}

                    {/* <div className="field-wrapper col-12">
                      <label className='form-label' htmlFor="email">Firm Email<span className='text-danger'>*</span></label>
                      <input type="email" id="email" name="email" required className='form-control'  onChange={(e)=> setFirmEmail(e.target.value)}/>
                    </div> */}

                    {/* <div className="field-wrapper col-12">
                    <label className='form-label' htmlFor="firm_phone_number"> Firm Phone Number<span className='text-danger'>*</span></label>
                    <input type="tel" id="firm_phone_number" name="firm_phone_number"  required className='form-control' onChange={(e)=> setFirmPhoneNumber(e.target.value)}/>
                  </div> */}

                    {/* <div className="field-wrapper col-12">
                      <label className='form-label' htmlFor="title"> Routes<span className='text-danger'>*</span></label>
                      {!referral_id &&
                      <Select
                        closeMenuOnSelect={true}
                        classNamePrefix='custom-input'
                        components={animatedComponents}
                        // options={allFrims}
                        // isMulti
                        onChange={handleChangeRoute}
                        options={allArchitectRoutesOpt}
                        // onInputChange={handleSearch}
                        placeholder='Select Route'
                        name='architecture_Firm'
                        required
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            width: '100%',
                          }),
                        }}
                        isDisabled={referral_id?true:false}
                        // defaultValue={allReprentativesOpt[0]}
                      />}
                      { referral_id && (
                          <input type="text" id="architecture_Firm" name="architecture_Firm"  defaultValue={referral_id}  className='form-control cursor-disable opacity-75' readOnly disabled/>
                      )}
                    </div> */}
                    {/*<div className="field-wrapper col-12">
                      <label className='form-label' htmlFor="title"> Architect Type<span className='text-danger'>*</span></label>
                      <Select
                        closeMenuOnSelect={true}
                        classNamePrefix='custom-input'
                        components={animatedComponents}
                        // options={allFrims}
                        // isMulti
                        onChange={handleChangeArchitectType}
                        options={architectTypesOpt}
                        // onInputChange={handleSearch}
                        placeholder='Select Architectureal Firm'
                        name='architecture_Firm'
                        required
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            width: '100%',
                          }),
                        }}
                      />
                    </div> */}

                  </>
                )}



                <div className="submit-btn-wrapper col-12 pt-3">
                  <button type='submit' className="btn w-100" disabled={appointmentLoader} >{appointmentLoader ? <ButtonBallsLoader /> : 'Submit'}</button>
                </div>
              </form>

            </div>
          </BackDrop>
        </Portal>
      )}
      {userReturned &&
        <Portal>
          <div  className='welcome-modal-wrapper'>
              <div className='welcome-modal-inner-wrapper'>
                <h2>Welcome back!</h2>
              </div>
          </div>
        </Portal>
      }
    </>
  )
}

export default Products