import React, { useEffect, useRef, useState, Suspense } from 'react'
// import LazyLoadImage from './lazyload/LazyLoadImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import imagess from '../../src/assets/images/loading-gif.gif';

const LazyLoadImage  = React.lazy(()=>import('./lazyload/LazyLoadImage'));


function ProductDetailCardGallery({ imgBaseUrl, galleryImages, handleGalleryImagesModal }) {
  // const [imgUrl, setImgUrl] = useState(null);
  // const [imagesGal, setImagesGal] = useState([]);

  const galleryRef = useRef(null);

  // useEffect(() => {
  //   galleryRef.current.style.display = 'none';
  //   setTimeout(() => {
  //   galleryRef.current.style.display = 'flex';
      
  //   }, 1000);
  // }, [])
  // useEffect(() => {
  //   setImagesGal(galleryImages)
  //   setImgUrl(imgBaseUrl);
  // }, [imgBaseUrl, galleryImages])
  return (
    <>
      <div className="detail-landing-images-wrapper" ref={galleryRef}>
      {galleryImages.length >=1 && galleryImages[0] &&
        <div className={`detail-landing-image-contianer detail-image-display-one ${galleryImages.length>1?'w-50':'w-100'}`}>
          {/* <img loading="lazy" src={`${imgUrl}/${galleryImages[0]?.image}`} alt={`${galleryImages[0]?.image}`} /> */}
        <Suspense fallback={<img src={imagess} alt='Loading . . .' width={100} height={100}/>}>
          <LazyLoadImage src={`${imgBaseUrl}/${galleryImages[0]?.image}`} alt={`${galleryImages[0]?.image}`}/>
        </Suspense>

          <div className="overlay" onClick={() => handleGalleryImagesModal(galleryImages, 0,0)}></div>
          {/* <div className="gallery-popup-btn" onClick={() => handleGalleryImagesModal(galleryImages, 0)}><i className="fa fa-search"></i></div> */}
        </div>
        }
        {galleryImages.length >= 2 && galleryImages[1]  &&
          <div className={`detail-landing-image-contianer detail-image-display-two ${galleryImages.lenght>=2?'w-50':'w-25'}`}>
            {/* <img loading="lazy" src={`${imgBaseUrl}/${galleryImages[1]?.image}`} alt={`${galleryImages[1]?.image}`} /> */}
            <Suspense fallback={<img src={imagess} alt='Loading . . .' width={100} height={100}/>}>
            <LazyLoadImage src={`${imgBaseUrl}/${galleryImages[1]?.image}`} alt={`${galleryImages[1]?.image}`}/>
            </Suspense>
            <div className="overlay" onClick={() => handleGalleryImagesModal(galleryImages, 1,0)}></div>
            {/* <div className="gallery-popup-btn" onClick={() => handleGalleryImagesModal(galleryImages, 1)}><i className="fa fa-search"></i></div> */}

          </div>
        }
        {galleryImages.length >=3 && galleryImages[2] &&
          <div className="detail-landing-image-contianer detail-image-display-three w-25">
            {/* <img loading="lazy" src={`${imgBaseUrl}/${galleryImages[2]?.image}`} alt={`${galleryImages[0]?.image}`} /> */}
            <Suspense fallback={<img src={imagess} alt='Loading . . .' width={100} height={100}/>}>
            <LazyLoadImage src={`${imgBaseUrl}/${galleryImages[2]?.image}`} alt={`${galleryImages[2]?.image}`}/>
           </Suspense>
            <div className="overlay" onClick={() => handleGalleryImagesModal(galleryImages, 2,0)}></div>
          </div>
        }
        <div className="gallery-popup-btn" onClick={() => handleGalleryImagesModal(galleryImages, 0,0)}> <FontAwesomeIcon icon={faSearch} /></div>
      </div>
    </>
  )
}

export default ProductDetailCardGallery