import React, { useCallback, useEffect, useState } from 'react'
import Header from '../components/header/Header'
import { Outlet, ScrollRestoration, useLocation, useParams } from 'react-router-dom'
import Footer from '../components/footer/Footer'
import ScrollToTop from '../components/ScrollToTop'
import Portal from '../react-portal/Portal'
import BackDrop from '../react-portal/BackDrop'
import { useLoginUserMutation } from '../redux/features/user/authApi'
import { useDispatch } from 'react-redux'
import { logoutUser, setUser } from '../redux/features/user/authSlice'
import { errorMessage,  successMessage } from '../components/toastMessages/ToastifyMsg'
import ButtonBallsLoader from '../components/loader/ButtonBallsLoader'

function RootLayout() {

  const { pathname } = useLocation();
  const { referral_id } = useParams();

  const renderHeader = () => {
    if (pathname === '/' && !referral_id) {
      return <Header handleOpenLogin={handleOpenLogin} handleLogout={handleLogout} />;
    }
    return null; 
  };

  useEffect(() => {
    if (pathname === '/' && !referral_id) {
      const app = document.getElementsByClassName('App')[0]; 
      if (app) {
        app.classList.add("home");
      }
    }
  }, [pathname, referral_id]);

  const renderFooter = () => {
    if (pathname !== '/') {
      return <Footer />;
    }
    return null; 
  };

  const [loginModal, setLoginModal] = useState(false);
  const [loading,setLoading] = useState(false);
  const [showPassword,setShowPassword] = useState(false);
  const [loginCreds,setLoginCreds] = useState({
    user_email:"",
    password: ""
  }) 

  const dispatch = useDispatch();

  const [userLogin] = useLoginUserMutation();

  const handleOpenLogin = useCallback(() => {
       setLoginModal(true);
    },[])
  

  const handleClose = useCallback(() => {
      setLoginModal(false);
    }, [])
  
  const handleChangeLoginCreds = (e)=>{
    const {name,value} = e.target;
    setLoginCreds(prev=>({ ...prev,[name]: value}))
  } 

  const handleSubmitLogin = async(e) => {
    e.preventDefault();
    try {
      setLoading(true);
      
      const {data,error} = await userLogin(loginCreds);
      if (data) {
        dispatch(setUser(data?.user));
        successMessage(data?.message);
        setShowPassword(false);
        setLoginModal(false);
      }
      if (error) {
        errorMessage(error?.data?.message);
      }
    } catch (error) {
      
    }
    finally{setLoading(false);}

  }

  const handleLogout = () => {
    dispatch(logoutUser());
    successMessage("Logout Successful.")
  }


  return (
    <div className="root-layout-wrapper">
      {/* <Header handleOpenLogin={handleOpenLogin} handleLogout={handleLogout}/> */}
      {renderHeader()}
      <div className="root-layout-inner">
        <Outlet />
      </div>
      {/* <Footer /> */}
      {renderFooter()}
      <ScrollToTop />
      <ScrollRestoration
          getKey={location=>{
            return location.pathname
          }}
          /> 


      {loginModal &&

       ( <Portal>
          <BackDrop handleClose={handleClose}>
            <>
              <div className="supplier-email-wrapper">
                <form onSubmit={handleSubmitLogin}>

                  <div className="field-wrapper">
                    <label className='form-label' htmlFor="user_email">Email</label>
                    <input type="email" id="user_email" name="user_email" onChange={(e)=>handleChangeLoginCreds(e)} placeholder='abc@gmail.com' defaultValue={loginCreds?.user_email} autoFocus required className='form-control' />
                  </div>

                  <div className="field-wrapper login-password">
                    <label className='form-label' htmlFor="password"> Password</label>
                    <input type={showPassword?'text':'password'} id="password" name="password" onChange={(e)=>handleChangeLoginCreds(e)} defaultValue={loginCreds?.password} required className='form-control' />
                    <i className={`far ${showPassword?'fa-eye':'fa-eye-slash'}`} onClick={()=>setShowPassword(!showPassword)}></i>
                    
                  </div>
                  <div className="submit-btn-wrapper">
                    <button type='submit' className="btn btn-login" disabled={loading}>{ loading? <ButtonBallsLoader/>:'Login'}</button>
                  </div>
                  
                </form>
              </div>

            </>
          </BackDrop>
        </Portal>)

      }
    </div>
  )
}

export default RootLayout