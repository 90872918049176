import React, {  useState } from 'react';
import ButtonBallsLoader from './loader/ButtonBallsLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

function DownloadFilesButton({ zipBaseUrl, zipEndpoint,}) {

    const [downloading,setDownloading] = useState(false);

    const handleDownloadFiles = async () => {
        try {
            
            setDownloading(true);
            const url = `${zipBaseUrl}/${zipEndpoint}`;
            

            const link = document.createElement('a');
            link.style.display = 'none';
            link.href = url;
            link.setAttribute('download', 'zipName');
            link.setAttribute('target', '_blank');
            
            document.body.appendChild(link);
            
            link.click();
            document.body.removeChild(link);
            // console.log(`${zipBaseUrl}/${zipEndpoint}`);
            // saveAs(`${zipBaseUrl}/${zipEndpoint}`); // Trigger download
            
            setTimeout(()=>{
                setDownloading(false)
            },400)
        } catch (error) {
            console.error('Error creating ZIP:', error);
            // Handle error gracefully for the user
        }
    }
    return (
        <button type='button' disabled={!downloading?false:true} className="social-links" onClick={handleDownloadFiles}> {!downloading? (<><FontAwesomeIcon icon={faDownload} /> <span>  Download All</span></>):<><ButtonBallsLoader/></>}</button>
    )
}

export default DownloadFilesButton