import React from 'react'
import FadeIn from 'react-fade-in/lib/FadeIn'
import { Link } from 'react-router-dom'

function Aboutus() {
    return (
        <div className="about-us-wrapper">
            <section className="banner-wrapper">
                <div className="banner-inner-wrapper" style={{ 'backgroundImage': "url('https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=1440,fit=crop/omick8k8j2/pexels-cottonbro-Y4LyPxxLeQIJDZ2N.jpg')", 'height': '400px', 'backgroundSize': 'cover', 'backgroundAttachment': 'fixed', 'backgroundPosition': 'center bottom', }}>

                    <div className="banner-inner-text ">
                        <FadeIn>
                            <h2>ABOUT <span style={{'color':'var(--theme-bg)'}}>Featured Products</span></h2>
                        </FadeIn>

                    </div>
                    <div className="overlay"></div>
                </div>
            </section>
            <div className="container">
            <FadeIn>
            <div className="about-us-inner-wrapper">
                <div className="about-us-title-container">
                    <h2 className="about-us-title">Welcome to Featured Products</h2>
                </div>

                <div className="about-us-content-wrapper">
                    <div className="about-us-content-inner-wrapper">
                        <div className="about-us-body">
                            <h2 className="about-us-content-title">Discover, Discuss, and Deploy with Featured Products</h2>
                            <p>
                                <strong> Featured Products</strong> offers building product manufacturers and their representatives a dynamic platform to showcase their product lines. Designed to simplify product introductions, facilitate real-time feedback, enable continuous discussions, and significantly enhance the ROI representatives contribute to the construction process, our service streamlines your product showcase through a state-of-the-art digital product line card.
                            </p>
                        </div>
                        {/* <div className="about-us-img"><img src="https://assets.zyrosite.com/omick8k8j2/pexels-max-vakhtbovych-YrDP4q776BHk9krV.jpg" alt="text"  /> </div> */}
                    </div>
                </div>

                <div className="about-us-content-wrapper">
                    <div className="about-us-content-inner-wrapper">
                        <div className="about-us-body">
                            <h2 className="about-us-content-title">What We Offer</h2>
                            <p>
                            At Featured Products, we recognize the challenges faced in gaining visibility and traction in the competitive building industry market. Our platform provides a robust digital showcase for products, maximizing the effectiveness of each interaction and making every product introduction impactful and measurable.
                            </p>
                        </div>
                        {/* <div className="about-us-img"><img src="https://assets.zyrosite.com/omick8k8j2/pexels-max-vakhtbovych-YrDP4q776BHk9krV.jpg" alt="text"  /> </div> */}
                    </div>
                </div>
                <div className="about-us-content-wrapper">
                    <div className='about-us-content-inner-wrapper'>
                        {/* <div className="about-us-img"><img src="https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=496,h=496,fit=crop/blackmore9wg5k/jean-philippe-delberghe-unsplash-m7oWojNW55faDkJ8.jpg" alt="text"  /> </div> */}
                        <div className="about-us-body">

                            <h2 className="about-us-content-title">Streamlined Product Introductions</h2>
                            <p>Leverage our platform to present your products with detailed descriptions, high-resolution images, and key specifications. Featured Products ensures that every product pitch is precise, engaging, and rich with information, helping representatives make a powerful impact right from the start.</p>
                        </div>

                    </div>
                </div>
                <div className="about-us-content-wrapper">
                    <div className="about-us-content-inner-wrapper">
                        <div className="about-us-body">
                            <h2 className="about-us-content-title">Enhancing ROI for Representatives</h2>
                            <p>Featured Products is uniquely designed to boost the return on investment for building product representatives. By streamlining the sales process and enhancing efficiency, our platform allows representatives to use real-time data and feedback to refine their strategies, target the right audience more effectively, and drive conversions with better-informed pitches.</p>

                        </div>
                        {/* <div className="about-us-img"><img src="https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=600,h=496,fit=crop/blackmore9wg5k/jean-philippe-delberghe-unsplash-d9vXvlrb1kiqyy6x.jpg" alt="text"  /> </div> */}
                    </div>
                </div>
                <div className="about-us-content-wrapper">
                    <div className="about-us-content-inner-wrapper">
                        {/* {/* <div className="about-us-img"><img src="https://assets.zyrosite.com/omick8k8j2/pexels-max-vakhtbovych-mk374qaaxPFGbxNn.jpg" alt="text"  /> </div> */} 
                        <div className="about-us-body">
                            <h2 className="about-us-content-title">Engaging and Immediate Feedback</h2>
                            <p>Our platform enables representatives to gather instant feedback directly from the market. This interactive communication is crucial for understanding market needs, refining product details, and adjusting strategies based on real-world input, thereby maximizing effectiveness and ROI.</p>
                        </div>

                    </div>
                </div>
                <div className="about-us-content-wrapper">
                    <div className="about-us-content-inner-wrapper">
                        <div className="about-us-body">
                            <h2 className="about-us-content-title">Real-Time Discussions</h2>
                            <p>Featured Products facilitates real-time discussions, allowing representatives to quickly address inquiries, respond to feedback, and maintain continuous communication with potential clients. This keeps the sales momentum alive and builds stronger relationships.</p>

                        </div>
                        {/* <div className="about-us-img"><img src="https://assets.zyrosite.com/omick8k8j2/pexels-max-vakhtbovych-YrDP4q776BHk9krV.jpg" alt="text"  /> </div> */}
                    </div>

                </div>
                <div className="about-us-content-wrapper">
                    <div className="about-us-content-inner-wrapper">
                        <div className="about-us-body">
                            <h2 className="about-us-content-title">Why Featured Products?</h2>
                            {/* <p>Featured Products facilitates real-time discussions, allowing representatives to quickly address inquiries, respond to feedback, and maintain continuous communication with potential clients. This keeps the sales momentum alive and builds stronger relationships.</p> */}
                            <ul>
                                <li><strong>Targeted Exposure:</strong> Gain visibility among an engaged and specific audience looking for cutting-edge building solutions.</li>
                                <li><strong>Interactive Feedback:</strong> Receive and respond to user feedback instantly, helping you to iterate and improve your products swiftly.</li>
                                <li><strong>Continuous Communication:</strong> Maintain open lines of communication with potential clients through our real-time discussion tools, ensuring that no query goes unanswered.</li>
                            </ul>
                        </div>
                        {/* <div className="about-us-img"><img src="https://assets.zyrosite.com/omick8k8j2/pexels-max-vakhtbovych-YrDP4q776BHk9krV.jpg" alt="text"  /> </div> */}
                    </div>

                </div>

                <div className="about-us-content-wrapper">
                    <div className="about-us-content-inner-wrapper">
                        <div className="about-us-body">
                            <h2 className="about-us-content-title">Who We Serve</h2>
                            <p>Featured Products is the trusted resource for manufacturers and representatives aiming to make a significant impact with their product lines. We facilitate a connection between product development and market implementation, making it easier for everyone involved to achieve success.</p>

                        </div>
                        {/* <div className="about-us-img"><img src="https://assets.zyrosite.com/omick8k8j2/pexels-max-vakhtbovych-YrDP4q776BHk9krV.jpg" alt="text"  /> </div> */}
                    </div>

                </div>
                <div className="about-us-content-wrapper">
                    <div className="about-us-content-inner-wrapper">
                        <div className="about-us-body">
                            <h2 className="about-us-content-title">Join Us</h2>
                            <p>Elevate your product strategy, streamline your market introduction, and enhance the ROI of your representatives with Featured Products. Join our platform today and start transforming the way you connect with the industry. Discover new possibilities and maximize your impact with Featured Products.</p>
                            <p>To Learn More, email us at <Link to='mailto:info@featuredproducts.info'>info@featuredproducts.info</Link></p>
                        </div>
                        {/* <div className="about-us-img"><img src="https://assets.zyrosite.com/omick8k8j2/pexels-max-vakhtbovych-YrDP4q776BHk9krV.jpg" alt="text"  /> </div> */}
                    </div>

                </div>

                
            </div>

            </FadeIn>
        </div>    

        </div>
    )
}

export default Aboutus