import './App.css';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import Products from './views/Products';
import RootLayout from './layout/RootLayout';
// import GalleryModal from './components/galleryModal/GalleryModal';
import Cart from './views/Cart';
import PageNotFound from './views/page-not-found/PageNotFound';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from './redux/features/user/authSlice';
import { useIdleTimer } from 'react-idle-timer';
import { successMessage } from './components/toastMessages/ToastifyMsg';
import Aboutus from './views/about-us/Aboutus';
import { setFeedbackUserEmpty } from './redux/features/feedbackuser/FeedbackUser';
import ReferralProducts from './views/ReferralProducts';
// import ProductDetail from './views/product-detail/ProductDetail';
// import Home from './views/Home';

function App() {
  const dispatch = useDispatch();
  const auth =  useSelector((state) => state.auth);

  const onIdle = () => {
    if (auth.user) {
      dispatch(logoutUser());
      successMessage("Automatically logged out due to inactivity");
    }
    dispatch(setFeedbackUserEmpty());
  }

  // const { start,getRemainingTime } =
   useIdleTimer({
    onIdle,
    // onActive,
    // onAction,
    timeout: 7_200_000,
    throttle: 500,
    // start: false,
    // startOnMount: false,
    // startManually: true,
  });
  // start();
  // getRemainingTime();
  // console.log(start());
  // start(false);
  // setInterval(() => {
  //   console.log(getRemainingTime())
  // }, 10000);


  const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path='/' element={<RootLayout/>}>
          {/* <Route index element={<Home/>}/> */}
          <Route index element={<Products />} />
          <Route path='/:referral_id' element={<ReferralProducts referral={true}/>} />
          <Route path='cart' element={<Cart/>} />
          <Route path='about-us' element={<Aboutus/>}/>
          {/* <Route path='test' element={<GalleryModal/>}/> */}
          <Route path='*' element={<PageNotFound/>}/>
          {/* <Route path='/product/product-detail' element={<ProductDetail/>}/> */}
        </Route>
    )
  )


  return (
    <div className="App">  
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
