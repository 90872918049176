import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
// import logo from '../../assets/images/logo.png'
import logo from '../../assets/images/logo-2.png'
import '../../assets/css/Header.css'
// import CartList from '../navCartList/CartList';
import Hamburger from './Hamburger';
import { useSelector } from 'react-redux';
// import BackDrop from '../../react-portal/BackDrop';
// import Portal from '../../react-portal/Portal';

function Header({handleOpenLogin,handleLogout}) {
    const { cart } = useSelector((state) => state.cart);
    // const [loginModal, setLoginModal] = useState(false);
    const auth =  useSelector((state) => state.auth);

    const [navbar, setNavbar] = useState(false)
    const headerRef = useRef(null);

    const changeBg = () => {
        if (window.scrollY > 95) {
            setNavbar(true)
        } else {
            setNavbar(false)
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", changeBg)
    })

    // const [showCart, setShowCart] = useState(false);
    // const handleCartClose = useCallback(() => setShowCart(false), []);

    // const handleOpenLogin = () => {
    //     console.log("loginModal")
    //     setLoginModal(true);
    // }

    // const handleSubmitLogin = (e) => {
    //   e.preventDefault();
    // }
    
    

    return (
        <>
            <header className={`header${navbar ? ' active' : ''}`} ref={headerRef}>
                <div className="container-fluid px-sm-5 px-2">
                    <nav>
                        {/* <div className="menu-icon">
                            <i className="fa fa-bars"></i>
                            <Hamburger />
                        </div> */}
                        <div className="logo-wrapper">
                            {/* <Link to='/'> */}
                                <img src={logo} alt='logo' width={'100%'} height={'100%'}></img> 
                            {/* </Link> */}

                        </div>

                        {/* <ul className="main-nav">
                            <li><NavLink to='/'>Home</NavLink></li>
                            <li><NavLink to="/">Products</NavLink></li>
                            <li><NavLink to="/about-us">About us</NavLink></li>

                        </ul> */}

                        <div className="add-to-cart-nav d-flex gap-2 align-items-center">
                            {/* <div className='cart-count-container' onClick={() => setShowCart(!showCart)}>
                                <i className="fa fa-shopping-cart"></i>
                                {cart && cart?.length > 0 &&
                                    <div className="cart-list-count-wrapper">
                                        <span className="cart-list-count">{cart?.length}</span>
                                    </div>

                                }

                            </div> */}
                            {/* {!auth?.user && */}
                            {/* <i className="fa fa-share-square" title='Copy Shareable Link'></i> */}
                                {/* <div className="Login-wrapper"> {auth?.user?<span onClick={handleLogout}>Logout</span> :<span className={auth?.user?'opacity-0':''} onClick={handleOpenLogin}>Login</span>}</div> */}
                            {/* } */}
                        </div>
                    </nav>
                </div>
            </header>

            {/* <CartList cart={cart} show={showCart} handleClose={handleCartClose} /> */}

           

        </>
    )
}

export default Header