import React from 'react'
import './ButtonBallsLoader.css'
function ButtonBallsLoader() {
    return (
        <>
            <div className="wrapper">
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="shadow"></div>
                <div className="shadow"></div>
                <div className="shadow"></div>
            </div>
        </>
    )
}

export default ButtonBallsLoader