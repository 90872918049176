import React, { useEffect } from 'react'
import '.././assets/css/Modal.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
// import FadeIn from 'react-fade-in/lib/FadeIn';





function BackDrop({ children, handleClose, classToAdd,title ,type, backDropDisable }) {

     useEffect(() => {
     document.body.style.overflow="hidden";
     document.body.style.paddingRight="10px";
    
      return () => {
        document.body.style.overflow="";
        document.body.style.paddingRight="";

      }
    }, [])

    useEffect(() => {
        if (handleClose) {
            const closeOnEscapeKey = e => {
                // console.log("KEY",e.key)
                return e.key === "Escape" ? handleClose() : null
            };
            window.addEventListener("keydown", closeOnEscapeKey);
            
            return () => {
                // console.log('unmount')
                window.removeEventListener("keydown", closeOnEscapeKey);
            };
        }

    }, [handleClose]);

    const handleModalClose = ()=>{
        if(!backDropDisable){
            handleClose();
        }
    }

    return (

        <div className="modal-custom" onClick={handleModalClose}>
            {/* <FadeIn> */}
            <div className="modal-container" onClick={e => e.stopPropagation()}>
                {!!title && (
                    <div className="modal-header">
                        <h4>{title}</h4>
                        {/* <span className='close' onClick={handleClose}></span> */}
                    </div>)}
                {/* {!title &&(
                        <span className='close' onClick={handleClose}></span>
                    )
                    } */}
                <div className='modal-body'>
                    {children}
                </div>
                {handleClose &&
                    <div className="close-modal-wrapper" onClick={handleClose}>
                        <FontAwesomeIcon icon={faTimes} />

                    </div>
                }
                {/* <span className={`closer ${classToAdd?classToAdd:''}`} onClick={handleClose}> <FontAwesomeIcon icon={faTimes} /></span> */}

            </div>

            {/* </FadeIn> */}
        </div>

    )
}

export default BackDrop