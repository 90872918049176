import { toast } from "react-toastify";
import { toastApiMessage } from "../../utils/utils";
// import toastSuccess from "../assets/images/svg/toastSuccess.svg";
// import toastAlert from "../assets/images/svg/toastAlert.svg";
// import { toastApiMessage } from "../";
// import { toastApiMessage } from "../../utils/utils";



export function errorMessage(msg, options = {}) {
    toast.error(toastApiMessage(msg));
}
export function successMessage(msg, options = {}) {
    if (typeof msg === "string") {
        toast.success(toastApiMessage(msg));
    } else {
        toast.success(toastApiMessage(msg), {
            // icon: ({ theme, type }) => <img src={toastSuccess} alt="success" />,
            // ...options,
        });
    }
}

// export function promiseMessage(messages){
//   const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 3000));
//     toast.promise(
//       resolveAfter3Sec,
//       {
//         pending: 'Promise is pending',
//         success: 'Promise resolved 👌',
//         error: 'Promise rejected 🤯'
//       }
//     )
        
// }
