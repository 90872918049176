import React from 'react'
import { Markup } from 'react-render-markup'
function Description({content}) {
  return (
    <>
        <Markup markup={content}/>
    </>
  )
}

export default Description