import React, { createRef, useCallback, useEffect, useRef, useState } from 'react'
import '../../assets/css/GalleryModal.css'
import VideoList from './VideoWrapper';
import DownloadFilesButton from '../DownloadFilesButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
// import PDFViewer from 'pdf-viewer-reactjs';
// import { useMemo } from 'react';


function GalleryModal({ handleClose, galleryImages, galleryIndex, path, mrfLogo, galleryFiles, galleryView, galleryFilesEndpoint}) {
    const [slideNumber, setSlideNumber] = useState(0);
    // const imageArry = useMemo(() => [], []);
    const [imageArray, setImageArray] = useState([]);
    const [manfLogo, setManfLogo] = useState(null);
    const [pdfFilesUrl, setPdfFilesUrl] = useState([]);
    const [videoFiles,setVideoFiles] = useState([]);

    const [tabType, setTabType] = useState(0);
    const [currentFile, setCurrentFile] = useState(0);
    const [currentVideo,setCurrentVideo] = useState(0);

    const [baseUrl, setBaseUrl] = useState(null);
    const paneWrapperScroll = useRef(null);
    const imageRefs = useRef([]);

    useEffect(() => {
        document.body.style.overflow="hidden";
        document.body.style.paddingRight="10px";
       
         return () => {
           document.body.style.overflow="";
           document.body.style.paddingRight="";
   
         }
       }, [])
    // imageRefs.current = galleryImages.map((_, i) => imageRefs.current[i] || createRef());


    useEffect(() => {
        setBaseUrl(path?.image_path);
        setImageArray(galleryImages)
        setSlideNumber(galleryIndex);
        setManfLogo(mrfLogo);
        
        const filterPdfs = galleryFiles?.filter((filename)=>{
            const filenameParts = filename?.file.split('.');
            const fileExtension = filenameParts[filenameParts.length - 1];
            return (fileExtension === 'pdf');
        })
        setTabType(filterPdfs.length > 0? galleryView:0);
        setPdfFilesUrl(filterPdfs);

        const filteredVideos =  galleryFiles?.filter((filename)=>{
            const filenameParts = filename?.file.split('.');
            const fileExtension = filenameParts[filenameParts.length - 1];
            return fileExtension === 'mp4';
        })
        setVideoFiles(filteredVideos);
        
    }, [galleryImages, path, galleryIndex, mrfLogo, galleryFiles, galleryView]);

    useEffect(() => {
     document.body.style.overflow="hidden";
    
      return () => {
        document.body.style.overflow="";
      }
    }, [])
    




    // useEffect(() => {
    //     setSlideNumber(galleryIndex)
    // }, [galleryIndex])

    // useEffect(() => {
    //     const closeOnEscapeKey = e => {
    //         // console.log("KEY",e.key)
    //         return e.key === "Escape" ? handleClose() : null
    //     };

    //     window.addEventListener("keydown", closeOnEscapeKey);


    //     return () => {
    //         // console.log('unmount')
    //         window.removeEventListener("keydown", closeOnEscapeKey);
    //     };
    // }, [handleClose]);
    // useEffect(() => {
    //     if (paneWrapperScroll.current && imageRefs.current[slideNumber]) {
    //       paneWrapperScroll.current.scrollTo({
    //         top: imageRefs.current[slideNumber].current.offsetTop,
    //         behavior: 'smooth'
    //       });
    //     }
    //  }, [slideNumber]);

    const handleSelectImage = (slideIndex) => {
        setSlideNumber(null);
        setTimeout(() => {
            setSlideNumber(slideIndex);
        }, 100);
        // setSlideNumber(slideIndex)
    }


    const prevSlide = useCallback(() => {
        let paneWrapper = paneWrapperScroll.current;
        setSlideNumber(null);
        // setTimeout(() => {
            if (slideNumber === 0) {
                setSlideNumber(imageArray.length - 1);
                paneWrapper.scrollTo(0, paneWrapper.scrollHeight - paneWrapper.clientHeight);
            }
            else {
                setSlideNumber(slideNumber - 1);
            }
        // }, 100);
    }, [slideNumber, imageArray.length])

    // Next Image  
    const nextSlide = useCallback(() => {

        let paneWrapper = paneWrapperScroll.current;
        setSlideNumber(null);
        // setTimeout(() => {
            if (slideNumber + 1 === imageArray.length) {
                setSlideNumber(0);
                paneWrapper.scrollTo(0, 0);
            }
            else {
                // const element = document.getElementById(`gallery-pane-image-${slideNumber + 1}`);
                // paneWrapper.scrollTo(element,{ behavior: 'smooth' });
                setSlideNumber(slideNumber + 1);

            }
        // }, 100);
    }, [slideNumber, imageArray.length])

const prevDocument =useCallback( ()=>{
        try {
            if (currentFile === 0) {
                setCurrentFile(pdfFilesUrl.length - 1);
            }
            else {
                setCurrentFile(currentFile - 1);
            }
            
        } catch (error) {
            console.log("Error-prevDocument",error);
        }
    },[currentFile,pdfFilesUrl])

    const  nextDocument = useCallback(()=>{
        try {
            if (currentFile + 1 === pdfFilesUrl.length) {
                setCurrentFile(0);
            }
            else {
                setCurrentFile(currentFile + 1);
    
            }   
        } catch (error) {
            console.log("Error-nextDocument", error);
        }
    },[currentFile,pdfFilesUrl])

    useEffect(() => {
        const handleKeyDown = (event) => {
            switch (event.key) {
                case "ArrowLeft":
                    if (imageArray.length > 1 && tabType===0) {
                        prevSlide();
                    }
                    if (pdfFilesUrl.length > 0 && tabType===1) {
                        prevDocument();
                    }
                    break;
                case "ArrowRight":
                    if (imageArray.length > 1 && tabType===0) {
                        nextSlide();
                    }
                    if (pdfFilesUrl.length > 0 && tabType===1) {
                        nextDocument();
                    }
                    break;
                case "Escape":
                    handleClose();
                    break;
                default:
                // No action for other keys
            }
        };
        window.addEventListener("keydown", handleKeyDown);

        return () => {
            // console.log('unmount')
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleClose, prevSlide, nextSlide, imageArray.length,tabType,prevDocument,nextDocument,pdfFilesUrl.length]);


    

    return (
        <>
            <div className="gallery-modal-wrapper" onClick={handleClose}>
                <div className="gallery-viewer-wrapper" onClick={e => e.stopPropagation()}>
                    <div className="gallery-header-wrapper">
                        <div className="gallery-header-inner-wrapper">
                            <button type='button' className={`btn${tabType === 0 ? ' cursor-disable active' : ''}`} onClick={() => setTabType(0)}>Images</button>
                            {pdfFilesUrl.length > 0 &&
                                <button type='button' className={`btn${tabType === 1 ? ' cursor-disable active' : ''}`} onClick={() => setTabType(1)}>Documents</button>
                            }
                            {videoFiles.length > 0 &&
                                <button type='button' className={`btn${tabType === 2 ? ' cursor-disable active' : ''}`} onClick={() => setTabType(2)}>Videos</button>
                            }
                            
                        </div>
                    </div>
                    <div className="gallery-viewer-inner-wrapper">

                        <div className="gallery-pane-container">

                            <div className="gallery-pane-inner-container" ref={paneWrapperScroll}>
                                {tabType === 0 &&
                                    imageArray?.map((image, index) => (
                                    <div key={index} className={`gallery-pane-image-wrapper${slideNumber === index ? ' active' : ''}${index % 3 === 2 ? ' single-image' : ' two-images'}`}>
                                        <div className="pane-image" onClick={() => handleSelectImage(index)}>
                                            <img ref={imageRefs.current[index]} src={`${baseUrl}/${image?.image}`} alt='galleryImage' id={`gallery-pane-image-${index}`} />
                                        </div>
                                    </div>
                                    ))
                                }

                                {tabType ===1 && 
                                    pdfFilesUrl.map((file,index)=>{
                                       return  <div key={`${file?.product_id}${file?.id}`} className={`single-pane-pdf-wrapper${index===currentFile?' active':''}`} onClick={()=>setCurrentFile(index)} > <iframe src={pdfFilesUrl[index].file_path} title={file?.file} /><div className="pdf-select-overlay" onClick={()=>setCurrentFile(index)}></div></div>
                                    })
                                }
                                {tabType ===2 &&
                                    <VideoList videoFiles={videoFiles} currentVideo={currentVideo} setCurrentVideo={setCurrentVideo}/>
                                }
                            </div>
                        </div>


                        <div className="gallery-full-image-container">
                            {/* {tabType ===1 && */}
                                <div className="gallery-manfacture-logo-wrapper">
                            
                                    <div className="gallery-manfacture-logo-inner-wrapper">
                                            <div className="social-links-container p-1 px-3">
                                                <DownloadFilesButton zipBaseUrl={path?.zip_path} zipEndpoint={galleryFilesEndpoint} />
                                            </div>
                                        {manfLogo &&<img src={manfLogo} alt='mrf-logo' /> }
                                        
                                    </div>
                                
                                </div>
                            {/* } */}
                            {/* {manfLogo && (tabType ===0 || tabType ===2) &&
                                <div className="gallery-manfacture-logo-wrapper">
                                    
                                    <div className="gallery-manfacture-logo-inner-wrapper">
                                    {manfLogo &&<img src={manfLogo} alt='mrf-logo' /> } 
                                    </div>
                                
                                </div>
                            } */}

                           

                            
                            {tabType === 0 &&
                                (<div className={`gallery-full-image-inner-container`}>
                                    <div className="full-image">
                                        {slideNumber !== null &&
                                            // <FadeIn delay={200}>
                                            <img src={`${baseUrl}/${imageArray[slideNumber]?.image}`} alt='fullImage'></img>
                                            // </FadeIn>
                                        }
                                    </div>
                                    {imageArray.length > 1 &&
                                        <>
                                            <div className="slider-action prev-slide" onClick={() => prevSlide()}><button className="btn" type='button'> <FontAwesomeIcon icon={faChevronLeft}/>
                                             <i className="fa fa-chevron-left btnPrev"></i>
                                             </button></div>
                                            <div className="slider-action next-slide" onClick={() => nextSlide()}><button type='button' className="btn">
                                                 <FontAwesomeIcon icon={faChevronRight}/>
                                                 <i className="fa fa-chevron-right btnPrev"></i>
                                                 </button></div>
                                        </>
                                    }
                                    <div className="count-container"> <span>{slideNumber + 1}/{imageArray.length}</span></div>
                                </div>)
                            }
                            {tabType === 1 && pdfFilesUrl.length > 0 &&
                                (
                                    <div className={`full-view-document`} >
                                        {/* <PDFViewer
                                        document={{
                                            url: pdfFilesUrl[0].file_path,
                                        }}
                                    /> */}
                                        {pdfFilesUrl.length > 0 &&
                                            // <Document
                                            //     file={`${pdfFilesUrl[1].file_path}`}
                                            //     //   file={'https://arxiv.org/pdf/quant-ph/0410100.pdf'}
                                            //     onLoadSuccess={onDocumentLoadSuccess} options={options}>

                                            //     <Page

                                            //         pageNumber={pageNumber}

                                            //     />
                                            // </Document>
                                            <iframe src={pdfFilesUrl[currentFile].file_path} title='pdf' loading="lazy"/>

                                        }
                                        {/* {Array.apply(null, Array(numPages))
                                            .map((x, i) => i + 1)
                                            .map((page) => {
                                                return (
                                                <Page
                                                    pageNumber={page}
                                                    renderTextLayer={false}
                                                    renderAnnotationLayer={false}
                                                />
                                                );
                                            })} */}
                                        {/* ))} */}
                                        {/* <p>
                                        Page {pageNumber} of {numPages}
                                    </p> */}
                                    <div className="count-container"> <span>{currentFile + 1}/{pdfFilesUrl.length}</span></div>
                                    {pdfFilesUrl.length > 1 &&
                                        <>
                                            <div className="slider-action prev-slide" onClick={() => prevDocument()}><button className="btn" type='button'> <FontAwesomeIcon icon={faChevronLeft}/>
                                             <i className="fa fa-chevron-left btnPrev"></i>
                                             </button></div>
                                            <div className="slider-action next-slide" onClick={() => nextDocument()}><button type='button' className="btn">
                                                 <FontAwesomeIcon icon={faChevronRight}/>
                                                 <i className="fa fa-chevron-right btnPrev"></i>
                                                 </button></div>
                                        </>
                                    }
                                    </div>
                                )
                            }
                            {tabType ===2 && videoFiles.length >0 && 
                                (<>
                                    <div className='full-view-video' key={videoFiles[currentVideo]?.file_path}>
                                        <video controls loop autoPlay muted key={videoFiles[currentVideo]}>
                                            <source src={videoFiles[currentVideo]?.file_path} type="video/mp4" />
                                            {/* <source src="movie.ogg" type="video/ogg"/> */}
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                </>
                                )
                            }
                        </div>


                    </div>

                    <span className='close' onClick={handleClose}></span>
                </div>
            </div>
        </>
    )
}

export default GalleryModal