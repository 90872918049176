import React, { useState } from 'react'
import CustomCheckBox from '../CustomCheckBox'
import { faTimes, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function CartCard({ item, divider,handleRemove }) {

    const [checked, setChecked] = useState(false)

    // console.log("item", item)


    const handleItemCheck = (e) => {
        // console.log(e.target.value)
        setChecked(!checked)
    }

    

    return (
        <>
            <div key={item?.id} className="cart-item-wrapper">
                <div className='col-12 col-lg-5 px-2'>
                    <div className="item-info-action">
                        <div className="col-1 action-wrapper" onClick={()=>handleRemove(item?.id)}> {/*<i className="fa fa-times"></i>*/}<FontAwesomeIcon icon={faTrashAlt} /> </div>
                        <div className="col-4 col-md-2 col-lg-4 item-image"><img src={item?.image} alt={item?.title} /></div>
                        <div className="col item-title-wrapper px-1"><h5 className='title'>{item?.title}</h5></div>
                    </div>
                </div>
                <div className='col-12 col-lg px-2'>
                    <div className="item-description-form-wrapper">
                        <div className='col-12 col-sm-4'>
                            <div className="item-selection-wrapper">
                                <div className="type-wrapper">
                                    <h5>Request Type*</h5>
                                </div>
                                <div className="item-single-selection">
                                    <CustomCheckBox id={item?.id} checkValue={"Brochure"} checked={checked} handleCheck={handleItemCheck} />
                                    <label htmlFor={item?.id} className="item-label">Brochure</label>
                                </div>

                                <div className="item-single-selection">
                                    <CustomCheckBox id={item?.id * 3} checkValue={"Sample"} checked={checked} handleCheck={handleItemCheck} />
                                    <label htmlFor={item?.id * 3} className="item-label">Sample</label>
                                </div>
                                <div className="item-single-selection">
                                    <CustomCheckBox id={item?.id * 4} checkValue={"Pricing"} checked={checked} handleCheck={handleItemCheck} />
                                    <label htmlFor={item?.id * 4} className="item-label">Pricing</label>
                                </div>
                                <div className="item-single-selection">
                                    <CustomCheckBox id={item?.id * 5} checkValue={"Spec Assistance"} checked={checked} handleCheck={handleItemCheck} />
                                    <label htmlFor={item?.id * 5} className="item-label">Spec Assistance</label>
                                </div>

                            </div>
                        </div>
                        <div className="col-12 col-sm ">
                            <div className="item-description">
                                {/* <div className="location-selection-wrapper">
                                    <select name="product[9055][location]" className="location empty form-control" required="required" aria-required="true">
                                        <option value="">Select your location</option>
                                        <option value="AL">AL</option>
                                        <option value="AK">AK</option>
                                        <option value="AZ">AZ</option>
                                        <option value="AR">AR</option>
                                        <option value="CA">CA</option>
                                        <option value="CZ ">CZ</option>
                                        <option value="NV">NV</option>
                                        <option value="WY">WY</option>
                                    </select>
                                </div> */}
                                <div className="feedback-wrapper">
                                    <textarea className="form-control" name="feedback" id={`feedback${item?.id}`} rows="4" cols="30" placeholder="Project info or Please Share Feedback" ></textarea>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {divider &&
                <div className="custom-divider">
                    <hr />
                </div>
            }
        </>
    )
}

export default CartCard