import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cart: [
    // {
    //     id: 58,
    //     title: "Eveneer: Australian-Made Panels For Australian Joinery",
    //     image: "https://media3.productnews.com.au/cache/92/b1/92b1026d7a5e198520f0900bc69949c0.jpg",
    //     quantity: 1
    // },
    // {
    //     id: 38,
    //     title: "Crafting first impressions",
    //     image: "https://media1.productnews.com.au/cache/88/76/88765c504a1915db60057255a7801d3a.jpg",
    //     quantity: 1
    // },
],
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
        // console.log("cartReducer",action)
        // console.log( "state.cart>>",state.cart)
        const product = action.payload;
        const existingProduct = state.cart.find((item) => item.id === product.id); 

      if (!existingProduct) {
        state.cart = [product, ...state.cart];
      } else {
        existingProduct.quantity++;
      }
    },
    removeFromCart: (state, action) => {
      state.cart = state.cart.filter((item) => item.id !== action.payload.id);
    },
    increaseQuantity: (state, action) => {
      const id = action.payload.id;
      const product = state.cart.find((item) => item.id === id);

      product.quantity++;
    },
    decreaseQuantity: (state, action) => {
      const id = action.payload.id;
      const product = state.cart.find((item) => item.id === id);

      if (product.quantity > 1) {
        product.quantity--;
      }
    },
  },
});

export const { addToCart, removeFromCart, increaseQuantity, decreaseQuantity } =
  cartSlice.actions;

export default cartSlice.reducer;
