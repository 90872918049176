import React, { useCallback, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import GallerySlider from './gallery/GallerySlider';
import FadeIn from 'react-fade-in/lib/FadeIn';
// import Select from 'react-select'
// import makeAnimated from 'react-select/animated';


// const animatedComponents = makeAnimated();

const feedbackOptions = [
    { value: 'Not Interested', label: 'Not Interested' },
    { value: "Haven't used this product in the past", label: "Haven't used this product in the past" },
    { value: 'I use this type product on projects', label: 'I use this type product on projects' },
    { value: "Download data for reference only", label: "Download data for reference only" },
]

const requestOptions = [
    { value: 'Lunch and Learn', label: 'Lunch and Learn' },
    { value: "Samples", label: "Samples" },
    { value: 'Pricing', label: 'Pricing' },
    { value: "Schedule a call", label: "Schedule a call" },
]


function QuickView({ imgArry, handleSubmit }) {
    const [reference, setReference] = useState(false);
    const quickCountRef = useRef(null);
    const quickCountTimeoutIdRef = useRef(null);
    const quickInnerRef = useRef(null);
    const quickInnerSliderRef = useRef(null);

    const showHideCount = useCallback(() => {
        const showClass = 'show';
        const displayClass = 'd-none'
        const countWrapper = quickCountRef.current;
        // const tagsWrapper = proTagsRef.current;

        if (countWrapper.classList.contains(showClass)) {
            // Clear any existing timeout.
            clearTimeout(quickCountTimeoutIdRef.current);

            quickCountTimeoutIdRef.current = setTimeout(() => {
                countWrapper.classList.remove(showClass);
                // tagsWrapper.classList.remove(displayClass);
            }, 2000);

        } else {
            // tagsWrapper.classList.add(displayClass);
            countWrapper.classList.add(showClass);

            // Clear any existing timeout.
            clearTimeout(quickCountTimeoutIdRef.current);

            quickCountTimeoutIdRef.current = setTimeout(() => {
                countWrapper.classList.remove(showClass);
                // tagsWrapper.classList.remove(displayClass);
            }, 2000);
        }
    }, []);



    return (
        <>
            <div className="quick-view-wrapper">
                <div className="quick-view-inner-wrapper" ref={quickInnerRef}>
                    <div className="product-img-slider-wrapper" ref={quickInnerSliderRef}>
                        <GallerySlider galleryImages={imgArry} showHideCount={showHideCount} countRef={quickCountRef} view={'quickView'} />
                    </div>
                    <div className="quick-view-product-detail-wrapper">
                        <div className="quick-view-product-detail-inner-wrapper">
                            <div className="quick-view-title-container">
                                <h2 className="quick-view-title"><span>{(Math.floor(Math.random() * 11))}.</span> James Hardie 12</h2>
                                {/* <h3 className="price-title">$30,023</h3> */}
                            </div>
                            <div className="quick-description">
                                <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.

                                    The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.</p>
                            </div>
                            <div className="quick-tags">
                                <div className="tags-inner-container"><div className="tag product-from"> <span>listed by redfin 14 hr ago</span></div><div className="tag type"> <span>3d walkthrough</span></div></div>
                            </div>

                            <div className="social-links-container">
                                <div className="social-links"> <Link to='/'> <i className="fa fa-download"></i> <span>  Download Brochure</span> </Link></div>
                                {/* <div className="social-links" onClick={()=>handleOpenEmailModal()}> <i className="fas fa-at"></i> <span> Email supplier</span></div> */}
                                <div className="social-links" onClick={() => setReference(!reference)}>
                                    <i className="fas fa-lightbulb"></i>
                                    <span>
                                        {/* <label htmlFor={inputId}>  */}
                                        I have a Project!
                                        {/* </label> */}
                                    </span>
                                </div>
                            </div>
                            <form onSubmit={handleSubmit}>
                                {reference && (
                                    <FadeIn>
                                        <div className="reference-projects-wrapper">
                                            <div className="field-wrapper">
                                                <input type="text" id='reference_1' name="reference_1" className="form-control" placeholder='Reference 1' />
                                            </div>
                                            <div className="field-wrapper">
                                                <input type="text" id="reference_2" name='reference_2' className="form-control" placeholder='Reference 2' />
                                            </div>

                                        </div>
                                    </FadeIn>
                                )
                                }
                                <div className="comment-wrapper">
                                    <div className="select-feeback-wrapper">
                                        {/* <Select
                                            closeMenuOnSelect={false}
                                            classNamePrefix='custom-input'
                                            components={animatedComponents}
                                            options={requestOptions}
                                            isMulti
                                            // onChange={handleFeedBackSelection}
                                            placeholder='Request . . .'

                                        /> */}

                                    </div>

                                    <div className="select-feeback-wrapper">


                                        {/* <Select
                                            closeMenuOnSelect={false}
                                            classNamePrefix='custom-input'
                                            components={animatedComponents}
                                            options={feedbackOptions}
                                            isMulti
                                            // onChange={handleFeedBackSelection}
                                            placeholder='Select Feedback'

                                        /> */}

                                    </div>

                                </div>

                                <div className='submit-btn-wrapper'>
                                    <button type="submit" className="btn btn-sm">Submit</button>
                                </div>

                            </form>


                            {/* <div className="quick-view-actions">
                                <div className="quick-add-to-cart">
                                    <i className="fa fa-list"></i>
                                    <i className="fa fa-plus"></i>
                                    <span>Add To Enquiry List</span>
                                </div>
                            </div> */}

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default QuickView