import React from 'react'

function CustomCheckBox({id,checkValue,checked,handleCheck,}) {
    // console.log('id',id,"checkValue",checkValue)
    return (
        <>
            <div className="checkbox-wrapper-custom">
                <input id={id} type="checkbox" value={checkValue} name={id} checked={checked} onChange={(e) =>handleCheck(e)} />
                <label className="cbx" htmlFor={id}>
                    <div className="flip">
                        <div className="front"></div>
                        <div className="back">
                            <svg width="16" height="14" viewBox="0 0 16 14">
                                <path d="M2 8.5L6 12.5L14 1.5"></path>
                            </svg>
                        </div>
                    </div>
                </label>
            </div>
        </>
    )
}

export default CustomCheckBox