import apiSlice from "../api/apiSlice";

export const productApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getProducts: builder.query({
            query: ({id,referral}) => `products?page=${id}&rep_id=${referral}`,
        }),

        getRequestOption: builder.query({
            query:()=>'values/information-types'
        }),

        getAllRoutes: builder.query({
            query:()=>'values/routes',
        }),

        getArchitectRoles: builder.query({
            query: ()=>'values/roles',
        }),

        getArchitectProjectType: builder.query({
            query: ()=>'values/project-types',
        }),

        getAllArchitectureFirms: builder.query({
            query: ()=>`architects`
        }),

        verifyUser: builder.mutation({
            query:(body)=>({
                method:"POST",
                url:'verify_user',
                body
            })
        }),

        addAppointment: builder.mutation({
            query: (body)=>({
                method: 'POST',
                url: 'add_appointment',
                body,
            })
        }),

        addArchitectContact: builder.mutation({
            query: (body)=>({
                method: "POST",
                url:`architects/add_architect_contact`,
                body
            })
        }),
        
        addArchitectFirm: builder.mutation({
            query: (body)=> ({
                method: "POST",
                url: `architects/add_architect_firm`,
                body
            })
        }),

        getAllReprentatives: builder.query({
            query:() => `reps`
        }),

        getSingleRep: builder.query({
            query: (rep)=> `reps/getSingle?rep_name=${rep}`
        }),

        captureVisitor: builder.mutation({
            query: (body)=>({
                method:"POST", 
                url:"add_visitor",
                body
            })
        })
    }),
    overrideExisting: true,
})

export const {
    useGetProductsQuery,
    useLazyGetProductsQuery,
    useGetRequestOptionQuery,
    useLazyGetRequestOptionQuery,
    useLazyGetAllRoutesQuery,
    useLazyGetArchitectRolesQuery,
    useLazyGetArchitectProjectTypeQuery,
    useLazyGetAllArchitectureFirmsQuery,
    useVerifyUserMutation,
    useAddAppointmentMutation,
    useAddArchitectContactMutation,
    useAddArchitectFirmMutation,
    useLazyGetAllReprentativesQuery,
    useLazyGetSingleRepQuery,
    useCaptureVisitorMutation,
} = productApi; 

