import React from 'react'
import '../../assets/css/Footer.css'
// import logo from '../../assets/images/FP-Logo-light.png'
import logo from '../../assets/images/logo.png'
import { Link } from 'react-router-dom'
function Footer() {



  // const handleSubmit = () => {

  // }
  return (
    <footer>
      <div className="footer-wrapper">
        <div className="footer-inner-wrapper">
          <div className="col-12">
            <div className="logo-wrapper">
              <div className="logo-inner-wrapper">
                {/* <Link to='/'> */}
                  <img src={logo} alt='logo' />
                {/* </Link> */}
              </div>
              {/* <div className="scheduler-wrapper">
                <form onSubmit={handleSubmit}>
                  <div className="form-inner">
                    <input type='email' name='email' id='email' required placeholder='Sign up for product discussions' autoComplete='off' />
                    <button type='submit'>Schedule A Discussion</button>
                  </div>
                </form>
              </div> */}

            </div>
          </div>

          {/* <div className="col-12">
            <div className="pages-contaienr">
              <ul className='mb-0'>
                <li><Link to='/'> Home</Link></li>
                <li><Link to='/'>Products</Link></li>
                <li><Link to='/about-us'>About us</Link></li>
              </ul>
            </div>
          </div> */}

          {/* <div className="col-sm-12 col-md-3">
            <div className="social-links">
              <Link to='/' className="social-buttons__button social-button social-button--linkedin" aria-label="LinkedIn">
                <span className="social-button__inner">
                  <i className="fab fa-linkedin-in"></i>
                </span>
              </Link>
              <Link to='/' className="social-buttons__button social-button social-button--twitter" aria-label="LinkedIn">
                <span className="social-button__inner">
                  <i className="fab fa-twitter"></i>
                </span>
              </Link>
              <div className="social"><i class="fab fa-twitter"></i></div>
              <div className="social"><i class="fab fa-linkedin-in"></i></div>
            </div>
          </div> */}
        </div>
      </div>
    </footer>
  )
}

export default Footer