import React from 'react'

function BallLoader() {
  return (
    <div className="balls-loader-inner-wrapper">
        <div className="balls-loader"></div>
    </div>
  )
}

export default BallLoader