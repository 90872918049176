import { createSlice } from "@reduxjs/toolkit";

const initialState ={
    feedbackUser: null
}

const feebackUserSlice = createSlice({
    name: 'feedbackUser',
    initialState,
    reducers: {
        setFeedbackUser : (state, {payload})=>{
            state.feedbackUser = payload;
        },
        setFeedbackUserEmpty : (state)=>{
            state.feedbackUser = null;
        }
    }
})

export const {setFeedbackUser, setFeedbackUserEmpty} = feebackUserSlice.actions;

export default feebackUserSlice.reducer;
