import { combineReducers } from "@reduxjs/toolkit";
import apiSlice from "./features/api/apiSlice";
import cartReducer from "./features/cart/cartSlice";
import authReducer from "./features/user/authSlice";
import feedbackUserReducer from './features/feedbackuser/FeedbackUser'

const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth: authReducer,
  cart: cartReducer,
  feedbackUser: feedbackUserReducer,
});

export default rootReducer;
