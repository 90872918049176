import React, { useEffect, useState } from 'react'
import CartCard from '../components/cartPageCard/CartCard'
import { useDispatch, useSelector } from 'react-redux';
import { removeFromCart } from '../redux/features/cart/cartSlice';
import { Link } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';

function Cart() {
    const cartListStore = useSelector((state) => state.cart);
    const [cartItems, setCartItems] = useState([]);
    const dispatch = useDispatch();
    // const navigate = useNavigate();


    useEffect(() => {
        setCartItems(cartListStore.cart);
    }, [cartListStore.cart])

    // useEffect(()=>{
    //     if (cartListStore.cart.length ===0) {
    //         setTimeout(() => {
    //             navigate('/');
    //         }, 300);
    //     }
    // },[cartListStore.cart,navigate])

    const handleRemove = (id) => {
        dispatch(removeFromCart({ id }));
    };


    const handleSubmitCart = (e) => {
        e.preventDefault();
        // console.log(e.target)
    }
    


    return (
        <>
            <div className="cart-wrapper">
                <div className="container">
                    <div className="cart-inner-wrapper">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to='/' style={{'color': 'var(--theme-bg)'}}>Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Enquiry List</li>
                        </ol>
                    </nav>
                        <div className="page-title-container">
                                <h3 className="page-title">Enquiry Details</h3>
                        </div>
                        <div className="cart-detail-list-wrapper">
                            {cartItems?.length === 0 ? (
                                <div className="text-center">No item in the Enquiry list.</div>
                            ):(
                                
                                cartItems.map((item, index) => {
                                    return <CartCard key={item?.id} item={item} divider={index !== cartItems.length - 1} handleRemove={handleRemove} />
                                })
                                )
                            }
                        </div>
                        {cartItems?.length !== 0  &&
                            (
                                <div className="cart-form-wrapper">
                                    <div className="cart-form-inner-wrapper">
                                        <form onSubmit={handleSubmitCart}>
                                            <div className="form-inner-container">
                                                <div className="col-md-6 col-12">
                                                    <div className="field-wrapper">
                                                        <input type='text' className="form-control" placeholder="Company"></input>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="field-wrapper">
                                                        <input type='tel' className="form-control" placeholder="Work Phone"></input>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="field-wrapper">
                                                        <input type='text' className="form-control" placeholder="Name*" required></input>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="field-wrapper">
                                                        <input type='tel' className="form-control" placeholder="Mobile*" required></input>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="field-wrapper">
                                                        <input type='email' className="form-control" placeholder="Email*" required></input>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="field-wrapper">
                                                        <textarea className="form-control" placeholder='Enter Your Message' rows={4}></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-12 ">
                                                    <div className="submit-cart-btn-wrapper">
                                                            <button type='submit' className='btn'>Send Request</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )
                        }


                    </div>
                </div>
            </div>
        </>
    )
}

export default Cart