import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user:null,
    VisitorEmail: null,
}

const authSlice = createSlice({
    name: "auth",
    initialState,
  
    reducers: {
      setUser: (state, { payload }) => {
        state.user = payload;
      },
      logoutUser: (state) => {
        state.user = null;
      },
      setVisitorUserEmail: (state,{payload})=>{
        state.VisitorEmail = payload
      }
    },
  })
  export const { setUser, logoutUser, setVisitorUserEmail } = authSlice.actions;

  export default authSlice.reducer